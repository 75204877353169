import URLS from '../config/urls';
import BaseAPI from './BaseApi';


class ConfigService {
    async getCompany(): Promise<any> {
        try {
            const response = await BaseAPI.get(`${URLS.COMPANY}/features`);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao buscar dados" }; // Retorna um objeto de erro
        }
    }

    async editCompany(company: any): Promise<any> {
        try {
            const response = await BaseAPI.put(`${URLS.COMPANY}/features`, company, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return response; // Retorna a resposta da API
        } catch (error) {
            return { error: "Erro ao editar", details: error }; // Retorna um objeto de erro
        }
    }
}

export default new ConfigService();