export const COOKIE_NAMES = {
    TOKEN: 'auth_token',
    EMAIL: 'email',
    UUID: 'lass-m-username!',
    NAME: 'lass-m-firstName!',
    REFRESH_TOKEN: 'refresh_token',
    USER_PREFERENCES: 'user_preferences',
    REMEMBER: 'remember'
    // Adicione outros cookies conforme necessário
};

export const COOKIE_OPTIONS = {
    TOKEN: { expires: 7 },  // 7 dias de expiração para o token
    REFRESH_TOKEN: { expires: 30 },  // 30 dias para o refresh token
    USER_PREFERENCES: { expires: 365 },  // 1 ano para preferências do usuário
    NOT_REMEMBER: { expires: 1 }, // 1 dias de expiração para o token
};
