import React from 'react';
import { Image, Modal } from 'antd';

interface ModalComponentProps {
    title?: string;
    isModalOpen: boolean;
    handleCancel: () => void;
    handleOk: () => void;
    itemAlocation?: any;
}

interface DescriptionItemProps {
    title: string;
    content: string;
    className?: string;
}

const DescriptionItem = ({ title, content, className }: DescriptionItemProps) => (
    <div title={content} className={`${className} flex gap-2 pb-1`}>
        <p className="text-sm inline-block text-gray-500 font-normal">{title}:</p>
        <p className="text-sm text-zinc-700 font-medium">{content}</p>
    </div>
);

const ModalImageLocker: React.FC<ModalComponentProps> = ({ itemAlocation, isModalOpen, title, handleCancel, handleOk }) => {

    return (
        <Modal width={400} title={title} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
            <div className="mb-2">
                <div className="grid grid-cols-2 gap-8 pt-2">
                    <DescriptionItem title="Porta" content={itemAlocation?.container?.name} />
                    <DescriptionItem title="Tamanho" content={itemAlocation?.container?.containerMeasure?.name} />
                </div>
                <div className="grid grid-cols-2 gap-8">
                    <DescriptionItem title="Altura" content={itemAlocation?.container?.containerMeasure?.height} />
                    <DescriptionItem title="Largura" content={itemAlocation?.container?.containerMeasure?.width} />
                </div>
            </div>

            <Image
                width={'100%'}
                src={itemAlocation?.photo}
                preview={false}
            />
        </Modal>
    );
};

export default ModalImageLocker;