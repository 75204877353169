import { makeAutoObservable, runInAction } from 'mobx';
import { DEFAULT_PAGE_SIZE } from '../config/config';
import LockersService from '../services/LockersService';
import { message } from 'antd';
import { formatContainerList, transformModulesToTableData } from '../utils/functions';
import { formatDateAndTime } from '../utils/dateFunctions';
import { transformToValueLabel } from '../utils/selectFunction';

class LockersStore {
    loading: boolean = false;
    sort?: string = '-date_created';
    lockersList: any[] = [];
    allocationList: any[] = [];
    locker: any | undefined;
    lockerMobile: any[] = [];
    lockerSelect: any[] = [];
    lockerStatus: any;
    totalPages: number = 0;
    page: number = 0;
    totalElements: number = 0;
    pageSize: number = DEFAULT_PAGE_SIZE;
    defaultparams = {
        pageSize: DEFAULT_PAGE_SIZE,
        page: 1,
    }


    constructor() {
        makeAutoObservable(this);
    }

    reset() {
        this.lockersList = [];
        this.allocationList = [];
        this.locker = undefined;
        this.lockerMobile = [];
    }

    async getList(params: any) {
        this.loading = true;
        const response = await LockersService.getList(params);

        const urlParams = new URLSearchParams(params);
        const pageSize = Number(urlParams.get('pageSize') || DEFAULT_PAGE_SIZE);

        runInAction(() => {
            if (!response.error) {
                this.lockersList = response.data.content;
                this.totalPages = response.data.totalPages;
                this.page = response.data.page;
                this.totalElements = response.data.totalElements;
                this.pageSize = pageSize;
            }
            this.loading = false;
        });

        return response;
    }

    async getLockerByUuid(uuid: string) {
        this.loading = true;
        const response = await LockersService.getLockerByUuid(uuid);

        runInAction(() => {
            if (response.error) {
                message.error('Erro ao buscar Locker');
                return;
            }
            this.locker = response.data;
            this.lockerStatus = transformModulesToTableData(this.locker.modules);

            this.lockerMobile = response.data.modules.reduce((acc: any, module: any) => {
                return acc.concat(module.containers);
            }, []);
        });

        this.loading = false;
        return response;
    }

    async getAllocationLocker(uuid: string) {
        const response = await LockersService.getAllocationLocker(uuid);

        runInAction(() => {
            if (response.error) {
                message.error('Erro ao buscar Locker');
                return;
            }
            this.allocationList = response.data.content.map((item: any) => ({
                ...item,
                dateAllocated: item.dateAllocated ? formatDateAndTime((item.dateAllocated)) : null,
                dateCreated: item.dateCreated ? formatDateAndTime((item.dateCreated)) : null,
                dateEmailNotification: item.dateEmailNotification ? formatDateAndTime((item.dateEmailNotification)) : null,
                dateMessageNotification: item.dateMessageNotification ? formatDateAndTime((item.dateMessageNotification)) : null,
                dateRetrieved: item.dateRetrieved ? formatDateAndTime((item.dateRetrieved)) : null,
                ports: formatContainerList(item.containerAllocations)
            }));
        });

        return response;
    }

    async export(params: any) {
        this.loading = true;
        const response = await LockersService.export(params);
        this.loading = false;

        runInAction(() => {
            if (!response.error) {
                message.success("Dados exportados com sucesso");
            } else {
                message.error(response.error);
            }
        });
        return response.data;
    }


    async getLockerAsyncSelect(name: string) {
        const filter = `name__icontains=${name}`;
        const sort = 'features__preRegisteredAllocateFlow__isnull=false'
        const response = await LockersService.getList({ filter, sort });

        runInAction(() => {
            if (!response.error) {
                this.lockerSelect = transformToValueLabel(response.data.content, 'uuid', 'name');
            } else {
                message.error(response.error);
            }
            this.loading = false;
        });
        return response;
    }
}

const lockersStore = new LockersStore();
export default lockersStore;