// src/components/organisms/LoginForm/index.tsx
import { Alert, Button } from 'antd';
import { Form, Formik } from 'formik';
import React, { Dispatch, SetStateAction } from 'react';
import authStore from '../../../../stores/authStore';
import validationSchema from './validationSchema';
import OtpComponent from '../../../atoms/OtpComponent';


interface CodeValidationProps {
    setStep: Dispatch<SetStateAction<number>>;
    setToken: Dispatch<SetStateAction<string>>;
    loading: boolean;
}

const CodeValidation: React.FC<CodeValidationProps> = ({ setStep, setToken, loading }) => {
    const initialValues = {
        code: '',
    };

    const handleSubmit = async (values: { code: string }) => {
        const response = await authStore.validateToken(values.code);

        if (!response.error) {
            setToken(values.code);
            setStep(3);
        }
    };

    return (

        <div className="flex flex-col items-center justify-center rounded-xl p-8 bg-white min-w-96" >
            <div className="flex flex-col items-center justify-center pb-6">
                <p className="font-semibold text-lg">Código de Verificação</p>
                <p className="text-slate-400 text-sm">Insira o código para redefinir sua senha.</p>
            </div>

            <div className="w-full text-center mb-6 max-w-80">
                <Alert message="Caso seu e-mail não chegue em alguns minutos verifique também sua caixa de Spam." type="success" />
            </div>

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {() => (
                    <div className="w-full">
                        <Form className="flex flex-col justify-start gap-6 w-full max-w-80">
                            <OtpComponent label="Digite o Código" name="code" length={6} />

                            <div className="pt-4 w-full">
                                <Button className="w-full mb-2" htmlType="submit" type="primary" loading={loading}>Enviar</Button>
                                <Button className="w-full" htmlType="submit" type="default" onClick={() => setStep(1)}>Voltar</Button>
                            </div>
                        </Form>
                    </div>
                )}
            </Formik>
        </div>
    );
};

export default CodeValidation;