import { Tabs, TabsProps } from 'antd';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TEXT_BUTTON_HEADER_LIST } from '../../../config/config';
import customerStore from '../../../stores/customerStore';
import lockersStore from '../../../stores/lockersStore';
import { CustomerModel } from '../../../types/CustomerModel';
import BreadCrumbComponent from '../../atoms/BreadCrumbComponent';
import ActionButton from '../../atoms/ButtonComponent';
import ContentBox from '../../molecules/ContentBox';
import LoadingComponent from '../../molecules/LoadingComponent';
import ListAlocattions from './ListAlocattions';
import LockerInformation from './LockerInformation';
import LockerMap from './LockerMap';
import { validationSchema } from './schema';
import Permission from '../../molecules/Permission';
import { PermissionLocker } from '../../../permissions/PermissionLocker';

const breadCrumb = [
    {
        title: <a href="/cliente">Listagem de Lockers</a>,
    },
    {
        title: <p>Cadastro de Lockers</p>,
    },
]

const CreateLockers: React.FC = observer(() => {
    const { uuid } = useParams<{ uuid: string }>();
    const [activeTabKey, setActiveTabKey] = useState('1');
    const { locker, loading } = lockersStore;
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768); // Define o limite de 768px para dispositivos móveis
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Chama imediatamente para verificar o tamanho da tela ao carregar

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const getInformation = async () => {
            if (uuid) {
                await lockersStore.getLockerByUuid(uuid);
            }
        };

        const getAllocation = async () => {
            if (uuid) {
                await lockersStore.getAllocationLocker(uuid);
            }
        };

        getAllocation();
        getInformation();

        // return () => {
        //     lockersStore.reset();
        // };
    }, [uuid]);

    const initialValues: any = {
        name: locker?.name || '',
        hoursToReverse: locker?.hoursToReverse || '',
    };

    // Faz o filtro da tabela
    const handleSubmit = async (values: any) => {
        let response;

        const createProps: CustomerModel = {
            ...values,
            isActive: true,
            organization: values?.organization?.option,
            countryCode: values?.countryCode?.label,
        }

        if (uuid) {
            response = await customerStore.editCustomer(createProps, uuid);
        } else {
            response = await customerStore.createCustomer(createProps);
        }

        if (!response.error) listRouter();
    };


    const listRouter = () => {
        navigate(`/lockers`);
    }

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Informação Geral',
            children: <LockerInformation />,
        },
        {
            key: '2',
            label: 'Lista Alocações',
            children: <ListAlocattions />,
        },
        {
            key: '3',
            label: 'Locker',
            children: <LockerMap isMobile={isMobile} />,
        },
    ];

    const itemsMobile: TabsProps['items'] = [
        {
            key: '1',
            label: 'Informação Geral',
            children: <LockerInformation />,
        },
        {
            key: '2',
            label: 'Lista Alocações',
            children: <ListAlocattions />,
        },
    ];

    const onChange = (key: string) => {
        setActiveTabKey(key);
    };

    return (
        <>
            <BreadCrumbComponent breadCrumbList={breadCrumb} textButton={TEXT_BUTTON_HEADER_LIST} onClick={() => listRouter()} />

            {loading &&
                <ContentBox className="mb-4 p-6" title="Locker" description='Gestão das alocações'>
                    <LoadingComponent />
                </ContentBox>
            }

            {!loading &&
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                >
                    {({ errors, validateForm }) => (
                        <Form>
                            <Tabs
                                className="mb-0"
                                type="card"
                                activeKey={activeTabKey}
                                onChange={onChange}
                                items={isMobile ? itemsMobile : items}
                            />


                            <div className="flex justify-end">
                                <ActionButton onClick={async () => {
                                    // Valida o formulário e atualiza o objeto errors
                                    const formErrors = await validateForm();
                                }} htmlType="submit" actionType="primary">{uuid ? 'Atualizar' : 'Cadastrar'}</ActionButton>
                            </div>
                        </Form>
                    )}
                </Formik>
            }
        </>
    );
});

export default CreateLockers;