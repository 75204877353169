// src/components/organisms/LoginForm/index.tsx
import { FileDoneOutlined, InboxOutlined, LogoutOutlined, SisternodeOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons';
import { Drawer, Menu, MenuProps } from 'antd';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './styles.scss';
import Cookies from 'js-cookie';
import { COOKIE_NAMES } from '../../../config/cookieConstants';
import UserHeaderComponent from '../../molecules/UserHeaderComponent';
import authStore from '../../../stores/authStore';

interface DrawerMenuProps {
    openMenu: boolean;
    setOpenMenu: Dispatch<SetStateAction<boolean>>;
}

type MenuItem = Required<any>['items'][number];

const DrawerMenu: React.FC<DrawerMenuProps> = ({ setOpenMenu, openMenu }) => {
    const [current, setCurrent] = useState('1');
    const navigate = useNavigate();
    const email = Cookies.get(COOKIE_NAMES.EMAIL);

    const items: MenuItem[] = [
        {
            key: 'administrador',
            label: 'Administradores',
            icon: <TeamOutlined />,
            visible: true,
        },
        {
            key: 'cliente',
            label: 'Cliente',
            icon: <UserOutlined />,
            visible: true,
        },
        {
            key: 'separador',
            label: 'Separador',
            icon: <SisternodeOutlined />,
            visible: true,
        },
        {
            key: 'lockers',
            label: 'Lockers',
            icon: <InboxOutlined />,
            visible: true,
        },
        {
            key: 'organizacao',
            label: 'Organização',
            icon: <FileDoneOutlined />,
            visible: true,
        },
        {
            key: 'alocacao',
            label: 'Alocação',
            icon: <InboxOutlined />,
            visible: true,
        },
        {
            key: 'logout',
            label: 'Desconectar',
            icon: <LogoutOutlined />,
            visible: true,
            onClick: () => authStore.logout(),
        },
    ];

    const onClick: MenuProps['onClick'] = (e) => {
        setCurrent(e.key);
        navigate(`/${e.key}`);
    };

    const filteredItems = items
        .filter(item => item.visible) // Filtra os itens principais com base na visibilidade
        .map(item => ({
            ...item,
            children: item.children?.filter((child: any) => child.visible), // Filtra os sub-itens (children)
        }));

    return (
        <Drawer title={null} headerStyle={{ display: 'none' }} bodyStyle={{ padding: '16px' }} style={{ background: '#292e39', color: "#fff" }} width={280} placement={'left'} onClose={() => setOpenMenu(false)} open={openMenu}>
            <UserHeaderComponent />

            <div className="menu-modal pt-4">
                <Menu
                    theme={'light'}
                    onClick={onClick}
                    defaultOpenKeys={['sub1']}
                    selectedKeys={[current]}
                    mode="inline"
                    items={filteredItems}// Filtra o item principal
                />
            </div>
        </Drawer>
    );
};

export default DrawerMenu;