import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TEXT_BUTTON_HEADER_LIST } from '../../../config/config';
import BreadCrumbComponent from '../../atoms/BreadCrumbComponent';
import ActionButton from '../../atoms/ButtonComponent';
import InputComponet from '../../atoms/InputComponet';
import ContentBox from '../../molecules/ContentBox';
import LoadingComponent from '../../molecules/LoadingComponent';
import organizationsStore from '../../../stores/organizationsStore';
import SelectComponent from '../../atoms/SelectComponent';
import customerStore from '../../../stores/customerStore';
import InputMaskComponent from '../../atoms/InputMaskComponent';
import { validationSchema } from './schema';
import { CustomerModel } from '../../../types/CustomerModel';
import { OrganizationModel } from '../../../types/OrganizationModel';
import AutocompleteComponent from '../../atoms/AutocompleteComponent';
import Permission from '../../molecules/Permission';
import { PermissionCustomer } from '../../../permissions/PermissionCustomer';

const breadCrumb = [
    {
        title: <a href="/dashboard">Dashboard</a>,
    },
    {
        title: <a href="/cliente">Listagem de Clientes</a>,
    },
    {
        title: <p>Cadastro de Cliente</p>,
    },
]

const CreateCustomer: React.FC = observer(() => {
    const { uuid } = useParams<{ uuid: string }>();
    const { organizationSelect } = organizationsStore;
    const { customer, countriesList, loadingCustomer, selectedCountry } = customerStore;
    const navigate = useNavigate();

    useEffect(() => {
        const getInformation = async () => {
            if (uuid) {
                await customerStore.getCustomerByUuid(uuid);

                if (customerStore.customer?.countryCode && customerStore.countriesList.length > 0) {
                    // Busca e define máscara em campo de telefone conforme ddd de país
                    var countryPhoneDigit = customerStore.countriesList.find(
                        (i) => i.option.phoneDigit == customerStore.customer!.countryCode
                    );
                    customerStore.setSelectedCountry(countryPhoneDigit.option);
                }
            }
        };
        const getCountry = async () => {
            await customerStore.getCountries();
        };

        getCountry();
        getInformation();

        return () => {
            customerStore.reset();
        };
    }, [uuid]);

    const initialValues: any = {
        firstName: customer?.firstName || '',
        lastName: customer?.lastName || '',
        email: customer?.email || '',
        phone: customer?.phone || '',
        organization: { value: customer?.organization?.fullName, label: customer?.organization?.uuid, option: customer?.organization },
        countryCode: { value: customer?.countryCode, label: customer?.countryCode, option: selectedCountry },
    };

    // Faz o filtro da tabela
    const handleSubmit = async (values: {
        firstName: string;
        lastName: string;
        email: string;
        phone: string;
        organization: { value: string, label: string, option: OrganizationModel }
        countryCode: { value: string, label: string }
    }) => {
        let response;

        const createProps: CustomerModel = {
            ...values,
            isActive: true,
            organization: values?.organization?.option,
            countryCode: values?.countryCode?.label,
        }

        if (uuid) {
            response = await customerStore.editCustomer(createProps, uuid);
        } else {
            response = await customerStore.createCustomer(createProps);
        }

        if (!response.error) listRouter();
    };

    const handleOrganizationSearch = async (name: string) => {
        await organizationsStore.getOrganizationsAsyncSelect(name);
    };

    const listRouter = () => {
        navigate(`/cliente`);
    }

    return (
        <>
            <BreadCrumbComponent breadCrumbList={breadCrumb} textButton={TEXT_BUTTON_HEADER_LIST} onClick={() => listRouter()} />

            {loadingCustomer &&
                <ContentBox className="mb-4 p-4 lg:p-6" title="Cadastro de Clientes" description='Tela para cadastro de Clientes'>
                    <LoadingComponent />
                </ContentBox>
            }

            {!loadingCustomer &&
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                >
                    <Form>
                        <ContentBox className="mb-4 p-4 lg:p-6" title="Cadastro de Clientes" description='Tela para cadastro de Clientes'>
                            <div className="flex w-full gap-4 pb-4">
                                <AutocompleteComponent
                                    name='organization'
                                    label='Organização'
                                    fetchOptions={(name) => handleOrganizationSearch(name)}
                                    options={organizationSelect ?? []}
                                />
                            </div>

                            <div className="flex w-full gap-4 pb-4 flex-col lg:flex-row">
                                <InputComponet label="Nome" name="firstName" id="firstName" />
                                <InputComponet label="Sobrenome" name="lastName" id="lastName" />
                            </div>

                            <div className="flex flex-col lg:grid grid-cols-12 gap-4">
                                <div className="col-span-6">
                                    <InputComponet label="Email" name="email" id="email" />
                                </div>

                                <div className="col-span-2">
                                    <SelectComponent label="DDI (Código do país)" name="countryCode" options={countriesList} onChange={(_, value) => customerStore.setSelectedCountry(value.option)} />
                                </div>
                                <div className="col-span-4" >
                                    <InputMaskComponent mask={customerStore.selectedCountry?.mask ?? "(99) 99999-9999"} size="large" label="Celular" name="phone" id="phone" />
                                </div>
                            </div>
                        </ContentBox>

                        <Permission permissionKeys={[PermissionCustomer.PUT, PermissionCustomer.POST]} buttonType>
                            <ActionButton htmlType="submit" actionType="primary">{uuid ? 'Atualizar' : 'Cadastrar'}</ActionButton>
                        </Permission>
                    </Form>
                </Formik >
            }
        </>
    );
});

export default CreateCustomer;