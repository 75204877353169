import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { DEFAULT_PAGE_SIZE } from '../config/config';


const useQueryParams = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const setQueryParams = (params: Record<string, any>) => {
        const currentParams = queryString.parse(location.search);
        const newParams = { ...currentParams, ...params };

        // Se params for um objeto vazio, deve remover todos os parâmetros da URL
        if (Object.keys(params).length === 0) {
            navigate({
                pathname: location.pathname,
                search: '',
            });
        } else {
            navigate({
                pathname: location.pathname,
                search: queryString.stringify(newParams),
            });
        }
    };

    return [queryString.parse(location.search), setQueryParams] as const;
};

export default useQueryParams;


type Mappings = {
    [key: string]: string; // Mapeia cada chave de query para a chave do parâmetro
};

type Params = {
    pageSize: number;
    page: number;
    sort?: string;
    params?: any;
    simbol?: string;
    secondSimbol?: string;
    [key: string]: any; // Permite adicionar outros parâmetros se necessário
};


export const buildParamsFromQuery = (queryParams: any, mappings: any): any => {
    var params: Params = {
        pageSize: DEFAULT_PAGE_SIZE,
        page: 1,
        filter: [],
        sort: '-date_created'
    };


    for (const [key, value] of Object.entries(queryParams)) {
        if (mappings[key]) {
            if (key === 'page' || key === 'pageSize') {
                // Converte para número
                params[key] = Number(value);
            } else if (key === 'sort') {
                params['sort'] = String(value);
            } else {
                // Adiciona cada filtro individualmente
                const mappedKey = mappings[key];
                //@ts-ignore
                params.filter.push(`${mappedKey}=${value}`);
            }
        }
    }

    return params;
};

