import URLS from '../config/urls';
import { AdministratorModel } from '../types/AdministratorModel';
import BaseAPI from './BaseApi';


class AdministratorService {
  // Lass
  async getPermissions(token: string | undefined): Promise<any> {
    try {
      const response = await BaseAPI.get(`${URLS.PERMISSIONS}`, {
        headers: {
          Authorization: token,
        },
      });
      return response; // Retorna a resposta da API
    } catch (e) {
      return { error: "Erro ao buscar dados" }; // Retorna um objeto de erro
    }
  }

  async getList(params: any): Promise<any> {
    try {
      const response = await BaseAPI.get(`${URLS.USER}`, { params });
      return response; // Retorna a resposta da API
    } catch (e) {
      return { error: "Erro ao buscar dados" }; // Retorna um objeto de erro
    }
  }

  async getAdministratorByUuid(uuid: string): Promise<any> {
    try {
      const response = await BaseAPI.get(`${URLS.USER}/${uuid}`);
      return response;
    } catch (e) {
      return { error: "Falha ao buscar administrador" };
    }
  }

  async createAdministrator(data: AdministratorModel): Promise<any> {
    try {
      const response = await BaseAPI.post(`${URLS.USER}/`, data);
      return response;
    } catch (e) {
      return { error: "Falha ao cadastrar administrador" };
    }
  }

  async updateAdministrator(uuid: string, data: AdministratorModel): Promise<any> {
    try {
      const response = await BaseAPI.put(`${URLS.USER}/${uuid}`, data);
      return response;
    } catch (e) {
      return { error: "Falha ao atualizar administrador" };
    }
  }

  async deleteAdministrator(uuid: string): Promise<any> {
    try {
      const response = await BaseAPI.delete(`${URLS.USER}/${uuid}`);
      return response;
    } catch (e) {
      return { error: "Falha ao atualizar administrador" };
    }
  }

  async export(params: any): Promise<any> {
    try {
      const response = await BaseAPI.get(URLS.USER + "/csv", { params });
      return response;
    } catch (e) {
      return { error: "Falha ao gerar csv" };
    }
  }
}

export default new AdministratorService();