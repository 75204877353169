import { Table, TableColumnsType, Tag } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import lockersStore from '../../../../stores/lockersStore';
import InputComponent from '../../../atoms/InputComponet';
import InputNumberComponente from '../../../atoms/InputNumberComponet';
import ContentBox from '../../../molecules/ContentBox';
import TableCardComponent from '../../../molecules/TableCardComponent';

interface DataType {
    organization: any;
    email: any;
    fullName: any;
    uuid: string;
    key: React.Key;
}


const LockerInformation: React.FC = observer(() => {
    const { locker, loading, lockerStatus } = lockersStore;

    const columns: TableColumnsType<DataType> = [
        {
            title: 'Nome',
            dataIndex: 'module',
            key: 'module',
        },
        {
            title: 'Total Portas',
            dataIndex: 'totalPorts',
            key: 'totalPorts',
        },
        {
            title: 'Ocupadas',
            dataIndex: 'occupied',
            key: 'occupied',
        },
        {
            title: 'Livres',
            dataIndex: 'free',
            key: 'free',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status) => {
                return (
                    <Tag color={status === 'Livre' ? 'green' : 'red'}>{status}</Tag>
                );
            },
        },
    ];

    return (
        <div>
            <ContentBox className="mb-4 p-4 lg:p-6" borderCustom='rounded-b-lg' title="Locker" description='Gestão das alocações'>
                <div className="w-full flex gap-4 flex-col lg:flex-row">
                    <InputComponent label="Nome" name="name" id="name" />
                    <InputNumberComponente label="Horas para Reversa*" name="hoursToReverse" id="hoursToReverse" />
                </div>
            </ContentBox>

            <ContentBox className="mb-4 p-6 hidden lg:block" title="Visão Geral do Locker" description='Visão Geral de informações do locker'>
                <Table
                    className="table-custom"
                    columns={columns}
                    dataSource={lockerStatus && lockerStatus.map((item: any, index: number) => ({
                        ...item,
                        key: item.uuid || index,
                    }))}
                    loading={loading}
                    size="middle"
                    bordered={false}
                    pagination={false}
                />
            </ContentBox>


            <TableCardComponent
                itemData={lockerStatus}
                titlePage="Visão Geral do Locker"
                viewItems={[{ name: 'Total', key: 'totalPorts' }, { name: 'Livres', key: 'free' }, { name: 'Ocupação', key: 'occupied' }]}
                titleKey="module"
                subTitleKey="status"
                inputName="fullName__icontains"
                placeholder="Pesquisar Separador"
                loading={loading}
            />
        </div>
    );
});

export default LockerInformation;