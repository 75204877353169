import { Button, Drawer } from 'antd';
import React, { Dispatch, SetStateAction } from 'react';
import { ParsedQuery } from 'query-string';
import FormSeparator from '../FormSeparator';


export const MultipleRule = [
    { value: 'HE', label: 'Ativo' },
    { value: 'IC', label: 'Inativo' }
];


interface DrawerSeparatorProps {
    isModalOpen?: boolean;
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
    queryParams: ParsedQuery<string>;
    setQueryParams: (params: Record<string, any>) => void;
}

const DrawerFilterSeparator: React.FC<DrawerSeparatorProps> = ({ isModalOpen, setIsModalOpen, queryParams, setQueryParams }) => {
    let formikSubmit: () => void;

    return (
        <Drawer
            title="Filtrar Separadores"
            width={'85%'}
            onClose={() => {
                setIsModalOpen(false);
            }}
            open={isModalOpen}
            footer={
                <div className="flex gap-4 justify-end">
                    <Button onClick={() => setIsModalOpen(false)}>Cancelar</Button>
                    <Button type="primary" onClick={() => {
                        if (formikSubmit) formikSubmit();
                        setIsModalOpen(false);
                    }}>
                        Filtrar
                    </Button>
                </div>
            }
        >
            <FormSeparator
                queryParams={queryParams}
                setQueryParams={setQueryParams}
                setSubmitForm={(submitForm) => {
                    formikSubmit = submitForm; // Armazena a função submitForm para uso posterior
                }}
            />
        </Drawer>
    );
};

export default DrawerFilterSeparator;
