import { ConfigProvider } from 'antd';
import ptBR from 'antd/es/locale/pt_BR';
import Cookies from 'js-cookie';
import React, { useState } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import CreateCustomer from './components/pages/CreateCustomer';
import ListAdministrator from './components/pages/ListAdministrator';
import ListCustomer from './components/pages/ListCustomer';
import ListLockers from './components/pages/ListLockers';
import ListSeparator from './components/pages/ListSeparator';
import LoginPage from './components/pages/LoginPage';
import PageNotFound from './components/pages/PageNotFound';
import ProtectedRoute from './components/protected/ProtectedRoute';
import { StoreProvider } from './context/StoreContext';
import MainLayout from './layouts/MainLayout';
import darkTheme from './themes/darktheme';
import lightTheme from './themes/lightTheme';
import CreateOrganization from './components/pages/CreateOrganization';
import CreateSeparator from './components/pages/CreateSeparator';
import ListOrganization from './components/pages/ListOrganization';
import CreateAdministrator from './components/pages/CreateAdministrator';
import CreateLockers from './components/pages/CreateLockers';
import ListAllocations from './components/pages/ListAllocation';
import CreateConfig from './components/pages/CreateConfig';
import CreateAllocation from './components/pages/CreateAllocation';

const authToken = Cookies.get('auth_token');
const isAuthenticated = !!authToken;

// const theme = {
//   token: { colorPrimary: '#7e7e7e' }, // Cor global escura, aplicada ao botão
// };

function App() {
  const [isDarkMode, setIsDarkMode] = useState(false);

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
    document.documentElement.classList.toggle('dark', !isDarkMode);
  };

  return (
    <StoreProvider>
      <ConfigProvider locale={ptBR} theme={isDarkMode ? darkTheme : lightTheme}>
        <Router>
          <Routes>
            {/* Rota de login pública */}
            <Route path="/login" element={<LoginPage />} />

            {/* Rotas protegidas dentro do layout principal */}
            <Route
              path="/"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <MainLayout setIsDarkMode={toggleTheme} />
                </ProtectedRoute>
              }
            >
              {/* Default */}
              <Route path="" element={<ListAdministrator />} />

              {/* Administradores */}
              <Route path="administrador" element={<ListAdministrator />} />
              <Route path="administrador/cadastro" element={<CreateAdministrator />} />
              <Route path="administrador/editar/:uuid" element={<CreateAdministrator />} />

              {/* Clientes */}
              <Route path="cliente" element={<ListCustomer />} />
              <Route path="cliente/cadastro" element={<CreateCustomer />} />
              <Route path="cliente/editar/:uuid" element={<CreateCustomer />} />

              {/* Separadores */}
              <Route path="separador" element={<ListSeparator />} />
              <Route path="separador/cadastro" element={<CreateSeparator />} />
              <Route path="separador/editar/:uuid" element={<CreateSeparator />} />

              {/* Lockers */}
              <Route path="lockers" element={<ListLockers />} />
              <Route path="locker/cadastro" element={<CreateLockers />} />
              <Route path="locker/editar/:uuid" element={<CreateLockers />} />


              {/* Organização */}
              <Route path="organizacao" element={<ListOrganization />} />
              <Route path="organizacao/cadastro" element={<CreateOrganization />} />
              <Route path="organizacao/editar/:uuid" element={<CreateOrganization />} />

              {/* Alocação */}
              <Route path="alocacao" element={<ListAllocations />} />
              <Route path="alocacao/cadastro" element={<CreateAllocation />} />
              <Route path="alocacao/editar/:uuid" element={<CreateAllocation />} />

              {/* Configuração */}
              <Route path="config" element={<CreateConfig />} />

              {/* Rota para página não encontrada */}
              <Route path="*" element={<PageNotFound />} />
            </Route>
          </Routes>
        </Router>
      </ConfigProvider>
    </StoreProvider>
  );
}

export default App;

