import { EditOutlined, FileImageOutlined } from '@ant-design/icons';
import { Empty, Tag } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { formatDateAndTime } from '../../../../../utils/dateFunctions';
import { getStatusOrderColor } from '../../../../../utils/statusColorMapper';
import ModalImageLocker from '../../../../organisms/ModalImageLocker';

interface DataType {
    organization: any;
    email: any;
    fullName: any;
    uuid: string;
    key: React.Key;
}


interface LockerMapProps {
    informationLocker?: any;
}

const LockerMap: React.FC<LockerMapProps> = observer(({ informationLocker }) => {
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [itemAlocation, setItemAlocation] = useState<any>();


    return (
        <div>

            {informationLocker &&
                <div className="border p-4 rounded-lg h-full w-full max-w-96">
                    <div className="flex justify-between border-b pb-4 mb-4 border-slate-100">
                        <div className="flex flex-col">
                            <p className="text-base font-bold">{informationLocker?.information?.customer?.fullName}</p>
                            <p>{informationLocker?.information?.customer?.organization?.fullName}</p>
                        </div>

                        <a href="" target="_blank" rel="noopener noreferrer" className="cursor-pointer">
                            <EditOutlined />
                        </a>
                    </div>

                    <div className="mb-4 flex flex-col gap-1">
                        <div className="flex justify-between">
                            <p>Código</p>
                            <p>{informationLocker?.information?.code}</p>
                        </div>
                        <div className="flex justify-between">
                            <p>Dt. Criação</p>
                            <p>{informationLocker?.information?.dateCreated}</p>
                        </div>
                        <div className="flex justify-between">
                            <p>Dt. Alocação</p>
                            <p>{informationLocker?.information?.dateAllocated}</p>
                        </div>
                        <div className="flex justify-between">
                            <p>Mensagem enviada</p>
                            <Tag className="mr-0" color={'green'}>Sim</Tag>
                        </div>
                        <div className="flex justify-between">
                            <p>Status</p>
                            <Tag className="mr-0" color={getStatusOrderColor(informationLocker?.information?.status.code)}>{informationLocker?.information?.status.name}</Tag>
                        </div>
                    </div>

                    <div className="flex flex-col gap-3">
                        {informationLocker?.information.containerAllocations.map((item: any) => (
                            <div className="p-3 bg-slate-50 rounded-md">
                                <div className="flex justify-between pb-2">
                                    <p className="text-base font-bold">Porta {item.container.name}</p>

                                    <div className="cursor-pointer" onClick={() => {
                                        setOpenModal(true);
                                        setItemAlocation(item)
                                    }
                                    }>
                                        <FileImageOutlined />
                                    </div>
                                </div>

                                <div>
                                    <div className="flex justify-between">
                                        <p>Tipo Porta</p>
                                        <p>{item.container.containerType.name}</p>
                                    </div>
                                    <div className="flex justify-between">
                                        <p>Tamanho</p>
                                        <p>{item.container.containerMeasure.name}</p>
                                    </div>
                                    <div className="flex justify-between">
                                        <p>Dentro do Locker</p>
                                        <p>{item.container.containerType.name}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            }

            {!informationLocker &&
                <div className="border p-2 rounded-lg h-full w-full">
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Clique em uma das portas com alocação para ver os detalhes" />
                </div>
            }

            {openModal && <ModalImageLocker itemAlocation={itemAlocation} title="Imagem Alocação" handleOk={() => setOpenModal(false)} isModalOpen={openModal} handleCancel={() => setOpenModal(false)} />}
        </div>
    );
});

export default LockerMap;