// src/components/organisms/LoginForm/index.tsx
import { MailFilled } from '@ant-design/icons';
import { Button } from 'antd';
import { Form, Formik } from 'formik';
import React, { Dispatch, SetStateAction } from 'react';
import authStore from '../../../stores/authStore';
import OtpComponent from '../../atoms/OtpComponent';
import validationSchema from './validationSchema';
import { useNavigate } from 'react-router-dom';


interface AuthenticationFormProps {
    setStep: Dispatch<SetStateAction<number>>;
}

const AuthenticationForm: React.FC<AuthenticationFormProps> = ({ setStep }) => {
    const { loginProps } = authStore;
    const navigate = useNavigate();

    const initialValues = {
        code: '',
    };

    const handleSubmit = async (values: { code: string; }) => {
        const result = await authStore.login(values.code);

        if (!result.error) {
            // Atualiza o estado de autenticação diretamente sem reload
            authStore.setAuthenticated(true);

            // Redireciona para o dashboard
            navigate('/dashboard');
        }
    };


    const resendEmail = () => {
        authStore.verifyTwoFactorAuth(loginProps.email, loginProps.password)
    }

    return (

        <div className="flex flex-col items-center justify-center rounded-xl p-8 bg-white min-w-96 max-w-96" >
            <div className="flex flex-col items-center justify-center pb-6">
                <div className="rounded-full mb-4 bg-background-main w-20 h-20 flex items-center justify-center">
                    <MailFilled className="text-xl text-primary-color" />
                </div>

                <p className="font-semibold text-lg pb-2">Verifique seu E-mail</p>
                <p className="text-sm text-center">Enviamos para você o e-mail de verificação <b>{loginProps.email}</b>, por favor verifique</p>
            </div>


            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {() => (
                    <div className="w-full">
                        <Form className="flex flex-col justify-start gap-4 w-full">
                            <OtpComponent name="code" length={7} />

                            <div className="pt-4 w-full">
                                <Button className="w-full mb-2" htmlType="submit" type="primary">Entrar</Button>
                                <Button className="w-full" htmlType="submit" type="default" onClick={() => setStep(1)}>Voltar</Button>
                            </div>

                            <div className="flex gap-1 justify-center text-center text-sm pt-4">Não recebeu o e-mail? <p onClick={() => resendEmail()} className="text-primary-color cursor-pointer">Reenviar</p></div>
                        </Form>
                    </div>
                )}
            </Formik>
        </div>
    );
};

export default AuthenticationForm;