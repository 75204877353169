import { Button } from 'antd';
import { Form, Formik } from 'formik';
import React from 'react';
import InputComponent from '../../../atoms/InputComponet';
import { ParsedQuery } from 'query-string';
import SelectComponent from '../../../atoms/SelectComponent';
import DatePickerRangeComponet from '../../../atoms/DatePickerRangeComponet';
import allocationStore from '../../../../stores/allocationStore';
import { formatFromDatePicker } from '../../../../utils/dateFunctions';
import { DEFAULT_PAGE_SIZE } from '../../../../config/config';
import moment, { Moment } from 'moment'
import dayjs, { Dayjs } from 'dayjs';

interface FormAllocationProps {
    queryParams: ParsedQuery<string>;
    setQueryParams: (params: Record<string, any>) => void;
    setSubmitForm: (submitForm: () => void) => void;
}

const dateNotificationItems = [
    {
        value: "True",
        label: "Não",
    },
    {
        value: "False",
        label: "Sim",
    },
];

const FormAllocation: React.FC<FormAllocationProps> = ({ queryParams, setQueryParams, setSubmitForm }) => {
    const { defaultparams } = allocationStore;

    const handleSubmit = async (values: any) => {
        const dateCreated = formatFromDatePicker(values.dateCreated, "YYYY-MM-DD");
        const dateAllocated = formatFromDatePicker(values.dateAllocated, "YYYY-MM-DD");
        const dateRetrieved = formatFromDatePicker(values.wdateRetrieved, "YYYY-MM-DD ");

        const newParams: any = {
            code__icontains: values.code__icontains || undefined,
            customer__fullName__icontains: values.customer__fullName__icontains || undefined,
            locker__name__icontains: values.locker__name__icontains || undefined,
            containerAllocations__container__name__icontains: values.containerAllocations__container__name__icontains || undefined,
            status__name__icontains: values.status__name__icontains || undefined,
            dateMessageNotification__isnull: values?.dateMessageNotification__isnull?.value || undefined,
            dateEmailNotification__isnull: values?.dateEmailNotification__isnull?.value || undefined,
            dateCreated__gte: dateCreated ? dateCreated[0] : undefined,
            dateCreated__lte: dateCreated ? dateCreated[1] : undefined,
            dateAllocated__gte: dateAllocated ? dateAllocated[0] : undefined,
            dateAllocated__lte: dateAllocated ? dateAllocated[1] : undefined,
            dateRetrieved__gte: dateRetrieved ? dateRetrieved[0] : undefined,
            dateRetrieved__lte: dateRetrieved ? dateRetrieved[1] : undefined,
            containerAllocations__observations__icontains: values.containerAllocations__observations__icontains || undefined,
        };

        // Usando URLSearchParams para montar múltiplos parâmetros `filter`
        const params = new URLSearchParams({
            page: '1',
            pageSize: String(DEFAULT_PAGE_SIZE),
        });

        Object.entries(newParams)
            .filter(([_, value]) => value !== undefined)
            .forEach(([key, value]: [any, any]) => params.append('filter', `${key}=${value}`));

        // Definindo os query params atualizados
        setQueryParams(newParams);

        // Chamando a API com os parâmetros múltiplos
        await allocationStore.getList(params);
    };



    // Função utilitária para validar e sanitizar datas
    function sanitizeDate(date: string | (string | null)[] | null): Dayjs | null {
        // Certifique-se de que o valor seja uma string válida
        if (typeof date === 'string') {
            return dayjs(date);
        }
        return null; // Retorna null para valores inválidos
    }

    const initialValues = {
        code__icontains: queryParams.code__icontains || undefined,
        customer__fullName__icontains: queryParams.customer__fullName__icontains || undefined,
        locker__name__icontains: queryParams.locker__name__icontains || undefined,
        containerAllocations__container__name__icontains: queryParams.containerAllocations__container__name__icontains || undefined,
        status__name__icontains: queryParams.status__name__icontains || undefined,
        dateMessageNotification__isnull: queryParams?.dateMessageNotification__isnull || undefined,
        dateEmailNotification__isnull: queryParams?.dateEmailNotification__isnull || undefined,
        dateCreated: queryParams?.dateCreated__gte || queryParams?.dateCreated__lte
            ? [
                sanitizeDate(queryParams.dateCreated__gte),
                sanitizeDate(queryParams.dateCreated__lte),
            ].filter((date): date is Dayjs => !!date)
            : undefined,
        dateAllocated: queryParams?.dateAllocated__gte || queryParams?.dateAllocated__lte
            ? [
                sanitizeDate(queryParams.dateAllocated__gte),
                sanitizeDate(queryParams.dateAllocated__lte),
            ].filter((date): date is Dayjs => !!date)
            : undefined,
        dateRetrieved: queryParams?.dateRetrieved__gte || queryParams?.dateRetrieved__lte
            ? [
                sanitizeDate(queryParams.dateRetrieved__gte),
                sanitizeDate(queryParams.dateRetrieved__lte),
            ].filter((date): date is Dayjs => !!date)
            : undefined,
        containerAllocations__observations__icontains: queryParams.containerAllocations__observations__icontains || undefined,
    };


    const handleClearAndSearch = async (resetForm: () => void) => {
        resetForm();
        setQueryParams({});
        await allocationStore.getList(defaultparams);
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            enableReinitialize
        >
            {({ submitForm, resetForm }) => {
                setSubmitForm(submitForm); // Passa o submitForm para o componente pai
                return (
                    <Form>
                        <div className="flex flex-col items-end w-full gap-4 pb-4 lg:flex-row">
                            <InputComponent label="Código" name="code__icontains" id="code__icontains" />
                            <InputComponent label="Cliente" name="customer__fullName__icontains" id="customer__fullName__icontains" />
                            <InputComponent label="Organização" name="organization__fullName__icontains" id="organization__fullName__icontains" />
                        </div>
                        <div className="flex flex-col items-end w-full gap-4 pb-4 lg:flex-row">
                            <InputComponent label="Locker" name="locker__name__icontains" id="locker__name__icontains" />
                            <InputComponent label="Porta" name="containerAllocations__container__name__icontains" id="containerAllocations__container__name__icontains" />
                            <InputComponent label="Status" name="status__name__icontains" id="status__name__icontains" />
                        </div>
                        <div className="flex flex-col items-end w-full gap-4 pb-4 lg:flex-row">
                            <SelectComponent label="Mensagem enviada" name="dateMessageNotification__isnull" options={dateNotificationItems} />
                            <SelectComponent label="E-mail enviado" name="dateEmailNotification__isnull" options={dateNotificationItems} />
                            <DatePickerRangeComponet name='dateCreated' label='Data Criação' />
                        </div>
                        <div className="flex flex-col items-end w-full gap-4 pb-4 lg:flex-row">
                            <DatePickerRangeComponet name='dateAllocated' label='Data Alocação' />
                            <DatePickerRangeComponet name='dateRetrieved' label='Data Retirada' />
                            <InputComponent label="Observação da Porta" name="containerAllocations__observations__icontains" id="containerAllocations__observations__icontains" />
                        </div>

                        <div className="flex items-end gap-4 justify-end hidden lg:flex">
                            <Button onClick={() => handleClearAndSearch(resetForm)}>
                                Limpar
                            </Button>
                            <Button htmlType="submit" type="primary">
                                Filtrar
                            </Button>
                        </div>
                    </Form>
                );
            }}
        </Formik >
    );
};

export default FormAllocation;
