import URLS from '../config/urls';
import BaseAPI from './BaseApi';


class CustomerService {
    async getList(params: any): Promise<any> {
        try {
            const response = await BaseAPI.get(`${URLS.CUSTOMER}/customer`, { params });
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao buscar dados" }; // Retorna um objeto de erro
        }
    }

    async getCountries(): Promise<any> {
        try {
            const response = await BaseAPI.get(`${URLS.COUNTRIES}`);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao buscar dados" }; // Retorna um objeto de erro
        }
    }

    async createCustomer(customer: any): Promise<any> {
        try {
            const response = await BaseAPI.post(`${URLS.CUSTOMER}/customer`, customer);
            return response; // Retorna a resposta da API
        } catch (error) {
            return { error: "Erro ao cadastrar", details: error };  // Retorna um objeto de erro
        }
    }

    async editCustomer(customer: any, uuid: string | undefined): Promise<any> {
        try {
            const response = await BaseAPI.put(`${URLS.CUSTOMER}/${uuid}/customer/`, customer);
            return response; // Retorna a resposta da API
        } catch (error) {
            return { error: "Erro ao cadastrar", details: error }; // Retorna um objeto de erro
        }
    }

    async getCustomerByUuid(uuid: any): Promise<any> {
        try {
            const response = await BaseAPI.get(`${URLS.CUSTOMER}/${uuid}/customer`);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao buscar dados" }; // Retorna um objeto de erro
        }
    }

    async deleteCustomer(uuid: string): Promise<any> {
        try {
          const response = await BaseAPI.delete(`${URLS.USER}/${uuid}/customer`);
          return response;
        } catch (e) {
            return { error: "Erro ao deletar cliente" };
        }
    }

    async export(params: any): Promise<any> {
        try {
          const response = await BaseAPI.get(URLS.USER + "/customer/csv", params);
          return response;
        } catch (e) {
          return { error: "Falha ao gerar csv" };
        }
      }
    
      async import(data: any): Promise<any> {
        try {
          const response = await BaseAPI.post(URLS.USER + "/customer/csv", data, {
            headers: {
              'Content-Type': 'multipart/form-data', // Define o tipo de conteúdo correto
          },
          });
          return response;
        } catch (e) {
          return { error: "Falha ao gerar csv" };
        }
      }

}

export default new CustomerService();