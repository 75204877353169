import { DeleteOutlined, EditOutlined, MoreOutlined } from '@ant-design/icons';
import type { TableColumnsType, TableProps } from 'antd';
import { message, Space, Table } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_PAGE_SIZE, TEXT_BUTTON_HEADER_CREATE } from '../../../config/config';
import useQueryParams, { buildParamsFromQuery } from '../../../hooks/useQueryParams';
import customerStore from '../../../stores/customerStore';
import { getSortField, getSortOrder, getUniqueFilters, getUniquePropertyFilters } from '../../../utils/tableFunctions';
import BreadCrumbComponent from '../../atoms/BreadCrumbComponent';
import ActionButton from '../../atoms/ButtonComponent';
import ContentBox from '../../molecules/ContentBox';
import ModalComponent from '../../organisms/ModalComponent';
import { exportToExcel } from '../../../utils/excelFunction';
import FileUpload from '../../atoms/FileUploadComponent';
import FormCustomer from './FormCustomer';
import TableCardComponent from '../../molecules/TableCardComponent';
import ButtonMobileComponent from '../../atoms/ButtonMobileComponent';
import DrawerFilterCustomer from './DrawerFilterCustomer';
import DrawerBottom from '../../organisms/DrawerButton';
import { PermissionCustomer } from '../../../permissions/PermissionCustomer';
import Permission from '../../molecules/Permission';
import { useAuth } from '../../protected/ProviderAuth';

interface DataType {
    organization: any;
    email: any;
    fullName: any;
    uuid: string;
    key: React.Key;
}

interface QueryParams {
    size?: number;
    page?: number;
    sort?: string;
    search?: string;
    fullName__icontains?: string;
    email__icontains?: string;
    organization__fullName__icontains?: string;
}

// BreadCrumb
const breadCrumb = [
    {
        title: <a href="/dashboard">Dashboard</a>,
    },
    {
        title: <p>Lista de Clientes</p>,
    },
]

const ListCustomer: React.FC = observer(() => {
    const { customerList, loading, page, totalElements, defaultparams, pageSize, sort } = customerStore;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [itemSelected, setItemSelected] = useState<any>();
    const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
    const [selectionCheckbox, setSelectionCheckbox] = useState<any>();
    const [queryParams, setQueryParams] = useQueryParams();
    const [bottomOptions, openBottomOptions] = useState<any>(false);
    const [openFilter, setOpenFilter] = useState<any>();
    const listRouter = () => { navigate(`/cliente/cadastro`); }
    const navigate = useNavigate();
    let formikSubmit: () => void;
    const { isColumnVisible } = useAuth();

    useEffect(() => {
        const mappings = {
            page: 'page',
            pageSize: 'pageSize',
            fullName__icontains: 'fullName__icontains',
            email__icontains: 'email__icontains',
            organization__fullName__icontains: 'organization__fullName__icontains',
            sort: 'sort',
        };

        const params = buildParamsFromQuery(queryParams, mappings);

        const fetchData = async () => {
            const urlParams = new URLSearchParams({
                page: String(params.page),
                pageSize: String(params.pageSize ? params.pageSize : DEFAULT_PAGE_SIZE),
                sort: String(params.sort)
            });

            // Adiciona cada filtro separadamente no URLSearchParams
            params.filter.forEach((filter: string) => urlParams.append('filter', filter));

            await customerStore.getList(urlParams);
        };

        fetchData();
    }, []);


    // Colunas da Tabela
    const columns: TableColumnsType<DataType> = [
        {
            title: 'Nome',
            dataIndex: 'fullName',
            key: 'fullName',
            filters: getUniqueFilters(customerList, 'fullName'),
            onFilter: (value, record) => record.fullName.indexOf(value as string) === 0,
            defaultSortOrder: getSortOrder('fullName', queryParams.sort),
            sorter: true,
        },
        {
            title: 'E-mail',
            dataIndex: 'email',
            key: 'email',
            filters: getUniqueFilters(customerList, 'email'),
            onFilter: (value, record) => record.email.indexOf(value as string) === 0,
            defaultSortOrder: getSortOrder('email', queryParams.sort),
            sorter: true,
        },
        {
            title: 'Organização',
            dataIndex: 'organization',
            key: 'organization__fullName',
            filters: getUniquePropertyFilters<any, 'organization', 'fullName'>(customerList, 'organization', 'fullName'),
            onFilter: (value, record) => record.organization?.fullName.indexOf(value as string) === 0,
            defaultSortOrder: getSortOrder('organization__fullName', queryParams.sort),
            sorter: true,
            render: (item) => {
                return (
                    <>{item && <p>{item?.fullName}</p>}</>
                );
            },
        },
        {
            title: 'Editar',
            dataIndex: 'edit',
            key: 'edit',
            align: 'center',
            width: 80,
            hidden: !isColumnVisible(PermissionCustomer.PUT),
            render: (text, record) => (
                <Space className="cursor-pointer">
                    <a href={`/cliente/editar/${record.uuid}`}>
                        <div><EditOutlined /></div>
                    </a>
                </Space>
            ),
        },
        {
            title: 'Deletar',
            dataIndex: 'delete',
            key: 'delete',
            align: 'center',
            width: 80,
            hidden: !isColumnVisible(PermissionCustomer.DELETE),
            render: (text, record) => (
                <Space className="cursor-pointer" onClick={() => showModal(record)}>
                    <div ><DeleteOutlined /></div>
                </Space>
            ),
        },
    ];

    const onChange: TableProps<DataType>['onChange'] = async (pagination, filters, sorter, extra) => {
        const params = buildParams(pagination, sorter, queryParams);
        await customerStore.getList(params);
    };

    const buildParams = (
        pagination: any,
        sorter: any,
        queryParams: QueryParams
    ) => {
        const getSort = getSortField(sorter);
        const currentPage = pagination.current || pagination;
        const pageSize = pagination.pageSize || queryParams.size || DEFAULT_PAGE_SIZE;

        const paramsUrl = {
            pageSize: pageSize,
            page: currentPage,
            sort: getSort || sort || queryParams.sort || '-date_created',
            fullName__icontains: queryParams.fullName__icontains || '',
            email__icontains: queryParams.email__icontains || '',
            organization__fullName__icontains: queryParams.organization__fullName__icontains || '',
        };

        const params = new URLSearchParams({
            page: currentPage,
            pageSize: String(DEFAULT_PAGE_SIZE),
            sort: getSort || sort || queryParams.sort || '-date_created',
        });

        if (queryParams.fullName__icontains) {
            params.append('filter', `fullName__icontains=${queryParams.fullName__icontains}`);
        }

        if (queryParams.email__icontains) {
            params.append('filter', `email__icontains=${queryParams.email__icontains}`);
        }

        if (queryParams.organization__fullName__icontains) {
            params.append('filter', `organization__fullName__icontains=${queryParams.organization__fullName__icontains}`);
        }

        setQueryParams(paramsUrl);
        return params;
    };

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
            setSelectionCheckbox(selectedRows);
        },
    };

    const deleteSelectedItems = async () => {
        try {
            if (selectionCheckbox !== undefined) {
                const results = await Promise.all(
                    selectionCheckbox.map((item: any) => customerStore.deleteCustomerAll(item.uuid))
                );
                const hasError = results.some(response => response?.error);
                if (hasError) {
                    message.error('Erro ao deletar uma ou mais cores.');
                    setSelectionCheckbox([]);
                } else {
                    setSelectionCheckbox([]);
                    message.success('Todos as cores foram deletados com sucesso!');
                    await customerStore.getList(defaultparams);
                }
                setIsModalDeleteOpen(false);
            } else {
                message.error('Selecione pelo menos um item da listagem');
            }
        } catch (error) {
            message.error('Erro ao tentar deletar as cores.');
        }
    };


    const handleDeleteItem = async () => {
        const response = await customerStore.deleteCustomer(itemSelected.uuid);
        if (!response?.error) {
            customerStore.getList(defaultparams);
            setIsModalOpen(false);
        }
    };

    const showModal = (record: any) => {
        setItemSelected(record);
        setIsModalOpen(true);
    };


    const handleExport = async () => {
        const params = new URLSearchParams();

        if (queryParams.fullName__icontains) {
            params.append('filter', `fullName__icontains=${queryParams.fullName__icontains}`);
        }

        if (queryParams.email__icontains) {
            params.append('filter', `email__icontains=${queryParams.email__icontains}`);
        }

        if (queryParams.organization__fullName__icontains) {
            params.append('filter', `organization__fullName__icontains=${queryParams.organization__fullName__icontains}`);
        }

        const dataExport = await customerStore?.export(params);

        if (dataExport) {
            exportToExcel(dataExport, "Tabela de Clientes.csv");
        }
    };

    const handleImport = async (imports: any) => {
        const formData = new FormData();
        formData.append("file", imports, "data.csv");
        await customerStore.import(formData);
    };

    const deleteAllModal = () => {
        if (selectionCheckbox?.length > 0) {
            setIsModalDeleteOpen(true);
        } else {
            message.warning('Por favor, selecione ao menos um item para realizar a exclusão.');
        }
    }

    const initialValues = {
        fullName__icontains: queryParams.fullName__icontains || '',
        email__icontains: queryParams.email__icontains || '',
        organization__fullName__icontains: queryParams.organization__fullName__icontains || '',
    };

    const handleSubmit = async (values: any) => {
        const newParams: any = {
            fullName__icontains: values.fullName__icontains || undefined,
            email__icontains: values.email__icontains || undefined,
            organization__fullName__icontains: values.organization__fullName__icontains || undefined
        };

        // Usando URLSearchParams para montar múltiplos parâmetros `filter`
        const params = new URLSearchParams({
            page: '1',
            pageSize: String(DEFAULT_PAGE_SIZE),
        });

        if (queryParams.sort) {
            params.append('sort', String(queryParams.sort));
        }

        if (newParams.fullName__icontains) {
            params.append('filter', `fullName__icontains=${newParams.fullName__icontains}`);
        }

        if (newParams.email__icontains) {
            params.append('filter', `email__icontains=${newParams.email__icontains}`);
        }

        if (newParams.organization__fullName__icontains) {
            params.append('filter', `organization__fullName__icontains=${newParams.organization__fullName__icontains}`);
        }

        // Definindo os query params atualizados
        setQueryParams(newParams);

        // Chamando a API com os parâmetros múltiplos
        await customerStore.getList(params);
    };

    const editRouter = (item: any) => {
        navigate(`/cliente/editar/${item.uuid}`);
    }

    const deleteItem = (item: any) => {
        const response = customerStore.deleteCustomer(item.uuid);
        return response
    }

    return (
        <>
            <BreadCrumbComponent breadCrumbList={breadCrumb} permission={PermissionCustomer.POST} textButton={TEXT_BUTTON_HEADER_CREATE} onClick={() => listRouter()} />

            <ContentBox className="mb-4 p-6 hidden lg:block" title="Listagem de Clientes" description='Tela de listagem de Clientes'>
                <FormCustomer
                    setSubmitForm={(submitForm) => {
                        formikSubmit = submitForm;
                    }}
                    queryParams={queryParams} setQueryParams={setQueryParams}
                />
            </ContentBox >

            <ContentBox className="mb-4 p-6 hidden lg:block" description='Lista de Clientes' actions={
                <>
                    <Permission permissionKeys={[PermissionCustomer.IMPORT]} removeType>
                        <FileUpload onFileProcessed={handleImport} requiredColumns={['Nome', 'Sobrenome', 'E-mail', 'Cód. País', 'Celular', 'Organização']} title="Importar Arquivo" />
                    </Permission>
                    <Permission permissionKeys={[PermissionCustomer.DELETE]} removeType>
                        <ActionButton icon actionType="delete" onClick={() => deleteAllModal()}>Deletar</ActionButton>
                    </Permission>
                    <Permission permissionKeys={[PermissionCustomer.EXPORT]} removeType>
                        <ActionButton icon actionType="export" onClick={() => handleExport()}>Exportar para Excel</ActionButton>
                    </Permission>
                </>
            }>
                <Table
                    className="table-custom"
                    columns={columns}
                    dataSource={customerList.map((item: any, index: number) => ({
                        ...item,
                        key: item.uuid || index,
                    }))}
                    onChange={onChange}
                    loading={loading}
                    rowSelection={rowSelection}
                    size="middle"
                    bordered={false}
                    pagination={{
                        current: page,
                        pageSize: pageSize,
                        total: totalElements,
                        showSizeChanger: true,
                        pageSizeOptions: ['20', '40', '60'],
                    }}
                />
            </ContentBox>

            {/* Responsive Component */}
            <TableCardComponent
                itemData={customerList}
                titlePage="Clientes"
                viewItems={[{ name: 'Organização', key: 'organization.name' }]}
                titleKey="fullName"
                subTitlePage="Tela de listagem de Clientes"
                subTitleKey="email"
                initialValues={initialValues}
                totalElements={totalElements}
                pageSize={pageSize}
                current={page}
                onChange={onChange}
                editRouter={editRouter}
                deleteItem={deleteItem}
                handleSubmit={handleSubmit}
                inputName="fullName__icontains"
                placeholder="Pesquisar Cliente"
                loading={loading}
                actions={
                    <>
                        <div style={{ background: '#FFFAF2' }} onClick={() => openBottomOptions(true)} className="bg-slate-50 flex min-w-10 min-h-10 justify-center rounded-full">
                            <MoreOutlined />
                        </div>
                    </>
                }
            />

            <ModalComponent
                title="Aviso"
                content={<p>Você deseja excluir o Cliente <b>{itemSelected?.firstName}</b>?</p>}
                isModalOpen={isModalOpen}
                handleOk={handleDeleteItem}
                handleCancel={() => setIsModalOpen(false)}
            />

            <ModalComponent
                title="Aviso"
                content={<p>Você está prestes a excluir vários itens da listagem, deseja prosseguir?</p>}
                isModalOpen={isModalDeleteOpen}
                handleOk={deleteSelectedItems}
                handleCancel={() => setIsModalDeleteOpen(false)}
            />

            <DrawerFilterCustomer queryParams={queryParams} setQueryParams={setQueryParams} isModalOpen={openFilter} setIsModalOpen={setOpenFilter} />

            <DrawerBottom openMenu={bottomOptions} setOpenMenu={openBottomOptions}>
                <p className="h-8" onClick={() => {
                    setOpenFilter(true);
                    openBottomOptions(false);
                }}>Filtro</p>

                <Permission permissionKeys={[PermissionCustomer.POST]} removeType>
                    <p className="h-8" onClick={() => {
                        handleExport();
                        openBottomOptions(false);
                    }}>Exportar para Excel</p>
                </Permission>

                <Permission permissionKeys={[PermissionCustomer.EXPORT]} removeType>
                    <p className="h-8" onClick={() => {
                        handleExport();
                        openBottomOptions(false);
                    }}>Exportar para Excel</p>
                </Permission>
            </DrawerBottom>
        </>
    );
});

export default ListCustomer;