import { Button } from 'antd';
import { Form, Formik } from 'formik';
import React from 'react';
import InputComponent from '../../../atoms/InputComponet';
import { DEFAULT_PAGE_SIZE } from '../../../../config/config';
import { ParsedQuery } from 'query-string';
import lockersStore from '../../../../stores/lockersStore';


interface FormLockersProps {
    queryParams: ParsedQuery<string>;
    setQueryParams: (params: Record<string, any>) => void;
    setSubmitForm: (submitForm: () => void) => void;
}

const FormLockers: React.FC<FormLockersProps> = ({ queryParams, setQueryParams, setSubmitForm }) => {
    const { defaultparams } = lockersStore;

    const handleSubmit = async (values: any) => {
        const newParams: any = {
            name__icontains: values.name__icontains || undefined,
            organization__fullName__icontains: values.organization__fullName__icontains || undefined,
        };

        // Usando URLSearchParams para montar múltiplos parâmetros `filter`
        const params = new URLSearchParams({
            page: '1',
            pageSize: String(DEFAULT_PAGE_SIZE),
        });

        if (values.name__icontains) {
            params.append('filter', `name__icontains=${values.name__icontains}`);
        }
        if (values.organization__fullName__icontains) {
            params.append('filter', `organization__fullName__icontains=${values.organization__fullName__icontains}`);
        }

        // Definindo os query params atualizados
        setQueryParams(newParams);

        // Chamando a API com os parâmetros múltiplos
        await lockersStore.getList(params);
    };


    const initialValues = {
        name__icontains: queryParams.name__icontains || '',
        organization__fullName__icontains: queryParams.organization__fullName__icontains || '',
    };

    const handleClearAndSearch = async (resetForm: () => void) => {
        resetForm();
        setQueryParams({});
        await lockersStore.getList(defaultparams);
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            enableReinitialize
        >
            {({ submitForm, resetForm }) => {
                setSubmitForm(submitForm); // Passa o submitForm para o componente pai
                return (
                    <Form>
                        <div className="flex flex-col items-end w-full gap-4 pb-4 lg:flex-row">
                            <InputComponent label="Nome" name="name__icontains" id="name__icontains" />
                            <InputComponent label="Organização" name="organization__fullName__icontains" id="organization__fullName__icontains" />

                            <div className="flex items-end gap-4 justify-end hidden lg:flex">
                                <Button onClick={() => handleClearAndSearch(resetForm)}>
                                    Limpar
                                </Button>
                                <Button htmlType="submit" type="primary">
                                    Filtrar
                                </Button>
                            </div>
                        </div>
                    </Form>
                );
            }}
        </Formik >
    );
};

export default FormLockers;
