import { Button } from 'antd';
import { Form, Formik } from 'formik';
import React from 'react';
import InputComponent from '../../../atoms/InputComponet';
import administratorStore from '../../../../stores/administratorStore';
import { DEFAULT_PAGE_SIZE } from '../../../../config/config';
import { ParsedQuery } from 'query-string';


interface DrawerPriceProps {
    queryParams: ParsedQuery<string>;
    setQueryParams: (params: Record<string, any>) => void;
    setSubmitForm: (submitForm: () => void) => void;
}

const FormAdministrator: React.FC<DrawerPriceProps> = ({ queryParams, setQueryParams, setSubmitForm }) => {
    const { defaultparams } = administratorStore;

    const handleSubmit = async (values: any) => {
        const newParams: any = {
            email__icontains: values.email__icontains || undefined,
            fullName__icontains: values.fullName__icontains || undefined,
            organization__fullName__icontains: values.organization__fullName__icontains || undefined,
        };

        // Usando URLSearchParams para montar múltiplos parâmetros `filter`
        const params = new URLSearchParams({
            page: '1',
            pageSize: String(DEFAULT_PAGE_SIZE),
        });

        if (values.email__icontains) {
            params.append('filter', `email__icontains=${values.email__icontains}`);
        }
        if (values.fullName__icontains) {
            params.append('filter', `fullName__icontains=${values.fullName__icontains}`);
        }
        if (values.organization__fullName__icontains) {
            params.append('filter', `organization__fullName__icontains=${values.organization__fullName__icontains}`);
        }

        // Definindo os query params atualizados
        setQueryParams(newParams);

        // Chamando a API com os parâmetros múltiplos
        await administratorStore.getList(params);
    };


    const initialValues = {
        fullName: queryParams.fullName || '',
        email__icontains: queryParams.email__icontains || '',
        organization__fullName__icontains: queryParams.organization__fullName__icontains || '',
    };

    const handleClearAndSearch = async (resetForm: () => void) => {
        resetForm();
        setQueryParams({});
        await administratorStore.getList(defaultparams);
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            enableReinitialize
        >
            {({ submitForm, resetForm }) => {
                setSubmitForm(submitForm); // Passa o submitForm para o componente pai
                return (
                    <Form>
                        <div className="flex flex-col items-end w-full gap-4 pb-4 lg:flex-row">
                            <InputComponent label="Nome" name="fullName__icontains" id="fullName__icontains" />
                            <InputComponent label="E-mail" name="email__icontains" id="email__icontains" />
                            <InputComponent label="Organização" name="organization__fullName__icontains" id="organization__fullName__icontains" />
                        </div>

                        <div className="flex items-end gap-4 justify-end hidden lg:flex">
                            <Button onClick={() => handleClearAndSearch(resetForm)}>
                                Limpar
                            </Button>
                            <Button htmlType="submit" type="primary">
                                Filtrar
                            </Button>
                        </div>
                    </Form>
                );
            }}
        </Formik >
    );
};

export default FormAdministrator;
