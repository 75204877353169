import { message } from 'antd';
import { makeAutoObservable, runInAction } from 'mobx';
import { DEFAULT_PAGE_SIZE } from '../config/config';
import { PaginationParams } from '../types';
import ColorService from '../services/ColorService';
import { transformToValueLabel } from '../utils/selectFunction';
import ConfigService from '../services/ConfigService';

class ConfigStore {
    loading: boolean = false;
    colorSelectList: any;
    companyProps: any;
    sort?: string = '-date_created';
    totalPages: number = 0;
    color: any | undefined;
    page: number = 0;
    totalElements: number = 0;
    size: number = DEFAULT_PAGE_SIZE;
    defaultparams = {
        size: DEFAULT_PAGE_SIZE,
        page: 0,
        sort: 'created,desc',
        search: '',
    }

    reset() {
        this.companyProps = undefined;
        this.colorSelectList = [];
    }

    constructor() {
        makeAutoObservable(this);
    }

    async getCompany() {
        this.loading = true;
        const response = await ConfigService.getCompany();

        runInAction(() => {
            if (!response.error) {
                this.companyProps = response.data;
            }
            this.loading = false;
        });

        return response;
    }

    async editCompany(company: any) {
        this.loading = true;

        const formData = new FormData();
        formData.append('onScreenName', company.onScreenName)
        formData.append('primaryColor', company.primaryColor)
        formData.append('secondaryColor', company.secondaryColor)

        if (company.logo[0] instanceof File) {
            const file = company.logo[0];
            formData.append('logo', file)
        }

        if (company.secondaryLogo[0] instanceof File) {
            const file = company.secondaryLogo[0];
            formData.append('secondaryLogo', file)
        }

        const response = await ConfigService.editCompany(formData);

        runInAction(async () => {
            if (response.error) {
                message.error(response?.details?.response?.data?.message);
            } else {
                message.success('Configuração editada com sucesso!');
                await this.getCompany();
            }
            this.loading = false;
        });

        return response;
    }
}

const configStore = new ConfigStore();
export default configStore;
