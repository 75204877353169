import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Drawer, Typography } from 'antd';
import React, { Dispatch, SetStateAction } from 'react';

const { Title, Paragraph } = Typography;

interface DrawerDeleteProps {
    openDeleteDranwer: boolean;
    setOpenDeleteDrawer: Dispatch<SetStateAction<boolean>>;
    deleteItem: (item: any) => void;
    itemData: any;
}

const DrawerDelete: React.FC<DrawerDeleteProps> = ({ openDeleteDranwer, setOpenDeleteDrawer, deleteItem, itemData }) => {

    return (
        <div>
            <Drawer
                placement="bottom"
                closable={false}
                onClose={() => setOpenDeleteDrawer(false)}
                open={openDeleteDranwer}
                height={280}
                footer={
                    <div className="flex gap-4 justify-end">
                        <Button
                            onClick={() => setOpenDeleteDrawer(false)}
                            className="border border-gray-300 rounded-lg px-4 py-2 hover:bg-gray-100 text-gray-700"
                        >
                            Cancelar
                        </Button>
                        <Button
                            type="primary"
                            danger
                            onClick={() => {
                                const response = deleteItem(itemData);
                            }}
                            className="rounded-lg px-4 py-2"
                        >
                            Confirmar
                        </Button>
                    </div>
                }
                className="bg-white shadow-lg rounded-t-lg"
            >
                <div className="flex flex-col items-center space-y-4">
                    {/* Ícone de alerta */}
                    <ExclamationCircleOutlined className="text-red-600 text-4xl" />

                    {/* Título e mensagem */}
                    <Title level={4} className="text-gray-800">
                        Tem certeza de que deseja deletar este item?
                    </Title>
                    <Paragraph className="text-sm text-gray-600 text-left">
                        Esta ação é irreversível. Ao confirmar, o item será removido permanentemente.
                    </Paragraph>
                </div>
            </Drawer>
        </div>
    )
};

export default DrawerDelete;