import URLS from '../config/urls';
import BaseAPI from './BaseApi';


class SeparatorService {
    async getList(params: any): Promise<any> {
        try {
            const response = await BaseAPI.get(`${URLS.SEPARATOR}`, { params });
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao buscar dados" }; // Retorna um objeto de erro
        }
    }

    async getSeparatorByUuid(uuid: string): Promise<any> {
        try {
          const response = await BaseAPI.get(`${URLS.USER}/${uuid}/separator`);
          return response;
        } catch (e) {
          return { error: "Falha ao buscar separador" };
        }
    }

    async createSeparator(data: any): Promise<any> {
      try {
        const response = await BaseAPI.post(`${URLS.USER}/separator`, data);
        return response;
      } catch (e) {
        return { error: "Falha ao criar separador" };
      }
    }

    async updateSeparator(uuid: string, data: any): Promise<any> {
      try {
        const response = await BaseAPI.put(
          `${URLS.USER}/${uuid}/separator/`,
          data
        );
        return response;
      } catch (e) {
        return { error: "Falha ao atualizar informações do separador" };
      }
    }

    async deleteSeparator(uuid: string): Promise<any> {
      try {
        const response = await BaseAPI.delete(`${URLS.USER}/${uuid}/separator`);
        return response;
      } catch (e) {
        return { error: "Não foi possível excluir o separador" };
      }
    }

    async export(params: any): Promise<any> {
      try {
        const response = await BaseAPI.get(URLS.USER + "/separator/csv", { params });
        return response;
      } catch (e) {
        return { error: "Falha ao gerar csv" };
      }
  }
}

export default new SeparatorService();