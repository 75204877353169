import { makeAutoObservable, runInAction } from 'mobx';
import { PaginationParams } from '../types';
import { DEFAULT_PAGE_SIZE } from '../config/config';
import UserService from '../services/UserService';

class UserStore {
    loading: boolean = false;
    sort?: string = '-date_created';
    userList: any[] = [];
    totalPages: number = 0;
    page: number = 0;
    totalElements: number = 0;
    size: number = DEFAULT_PAGE_SIZE;
    defaultparams = {
        size: DEFAULT_PAGE_SIZE,
        page: 0,
        sort: 'created,desc',
        search: '',
    }


    constructor() {
        makeAutoObservable(this);
    }

    async getList(params: PaginationParams) {
        this.loading = true;
        const response = await UserService.getList(params);

        runInAction(() => {
            if (!response.error) {
                this.userList = response.data.content;
                this.totalPages = response.data.totalPages;
                this.page = response.data.number;
                this.totalElements = response.data.totalElements;
                this.size = response.data.size;
            }
            this.loading = false;
        });

        return response;
    }
}

const userStore = new UserStore();
export default userStore;