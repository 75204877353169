import { Empty, Pagination } from 'antd';
import { Form, Formik } from 'formik';
import React, { ReactNode } from 'react';
import InputComponent from '../../atoms/InputComponet';
import DetailCardComponent from './DetailCardComponent';
import './styles.scss';
import LoadingComponent from '../LoadingComponent';
import { getNestedValue } from '../../../utils/functions';

interface ListViewItem {
    name: string; // Nome customizado a ser exibido
    key: string;  // Chave no objeto listItem
    type?: string;
}

interface BoxReportProps {
    titlePage?: string;
    subTitlePage?: string;
    editRouter?: (item: any) => void;
    deleteItem?: (item: any) => void;
    imageItem?: (item: any) => void;

    //List
    itemData?: any;
    viewItems?: ListViewItem[];
    titleKey?: string;

    //Input
    placeholder: string;

    //Actions
    actions?: ReactNode;

    //Table
    totalElements?: number;
    pageSize?: number;
    onChange?: any;
    loading?: boolean;
    current?: number;

    //Form
    subTitleKey?: string;
    initialValues?: any;
    handleSubmit?: (values: any) => Promise<void>;
    inputName?: string;
}

const TableCardComponent: React.FC<BoxReportProps> = ({ onChange, imageItem, pageSize, subTitlePage, itemData, titlePage, editRouter, deleteItem, current, actions, totalElements, viewItems, titleKey, subTitleKey, initialValues, placeholder, handleSubmit, loading, inputName }) => {

    return (
        <div className="block lg:hidden table-card">

            {initialValues && handleSubmit &&
                <div className="mb-4">
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        enableReinitialize
                    >
                        {() => (
                            <Form>
                                <div className="flex items-end w-full gap-2">
                                    <InputComponent name={inputName} id={inputName} placeholder={placeholder} />
                                    {/* <Button htmlType="submit" type="primary">Filtrar</Button> */}
                                </div>
                            </Form >
                        )}
                    </Formik>
                </div>
            }


            <div className="bg-white rounded">
                <div className="flex items-center justify-between mb-5 border-b border-gray-200 p-4 pb-5 gap-4">

                    <div className="flex flex-col">
                        <p className="text-lg font-bold">{titlePage}</p>
                        <p className="text-xs leading-5">{subTitlePage ? subTitlePage : 'Lorem Ipsum is simply dummy the printing and typesetting'}</p>
                    </div>

                    <div className="flex gap-2">
                        {actions}
                    </div>
                </div>

                {loading &&
                    <div className="p-6 bg-white rounded-lg">
                        <LoadingComponent />
                    </div>
                }

                {!loading &&
                    <>
                        {itemData?.length === 0 &&
                            <div className="bg-white p-4 rounded-lg">
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Nenhum Resultado encontrado" />
                            </div>
                        }


                        <div className="grid grid-cols-1 md:grid-cols-2 gap-3 px-4 pb-4 mb-4">
                            {itemData?.length > 0 && itemData.map((item: any, index: number) => (
                                <div key={index}>
                                    <DetailCardComponent viewItems={viewItems}
                                        itemData={item}
                                        title={titleKey && getNestedValue(item, titleKey)}
                                        subTitle={subTitleKey && getNestedValue(item, subTitleKey)}
                                        editRouter={editRouter}
                                        deleteItem={deleteItem}
                                        imageItem={imageItem}
                                    />
                                </div>
                            ))}
                        </div>

                        {itemData?.length > 0 && totalElements &&
                            <div className="flex justify-center pb-4">
                                <Pagination onChange={onChange} defaultCurrent={current} total={totalElements} pageSize={pageSize} />
                            </div>
                        }
                    </>
                }
            </div>
        </div>
    )
};

export default TableCardComponent;