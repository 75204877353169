import { makeAutoObservable, runInAction } from 'mobx';
import { DEFAULT_PAGE_SIZE } from '../config/config';
import SeparatorService from '../services/SeparatorService';
import { SeparatorModel } from '../types/SeparatorModel';
import { message } from 'antd';
import { CountryModel } from '../types/CountryModel';


class SeparatorStore {
    loading: boolean = false;
    sort?: string = '-date_created';
    separator?: SeparatorModel = undefined;
    separatorList: any[] = [];
    totalPages: number = 0;
    page: number = 0;
    totalElements: number = 0;
    pageSize: number = DEFAULT_PAGE_SIZE;
    defaultparams = {
        pageSize: DEFAULT_PAGE_SIZE,
        page: 1,
    }


    constructor() {
        makeAutoObservable(this);
    }

    reset() {
        this.separator = undefined;
    }


    async getList(params: any) {
        this.loading = true;
        const response = await SeparatorService.getList(params);

        const urlParams = new URLSearchParams(params);
        const pageSize = Number(urlParams.get('pageSize') || DEFAULT_PAGE_SIZE);

        runInAction(() => {
            if (!response.error) {
                this.separatorList = response.data.content;
                this.totalPages = response.data.totalPages;
                this.page = response.data.page;
                this.totalElements = response.data.totalElements;
                this.pageSize = pageSize;
            }
            this.loading = false;
        });

        return response;
    }

    async getSeparatorByUuid(uuid: string) {
        this.loading = true;
        const response = await SeparatorService.getSeparatorByUuid(uuid);

        runInAction(() => {
            if (!response.error) {
                this.separator = response.data;
            } else {
                message.error(response.error)
            }
            this.loading = false;
        });
        return response;
    }

    async export(params: any) {
        this.loading = true;
        const response = await SeparatorService.export(params);
        this.loading = false;
        runInAction(() => {
            if (response.error) {
                message.error(response.error);
                return undefined;
            }
        });
        return response.data;
    }

    async createSeparator(separator: SeparatorModel) {
        this.loading = true;
        const response = await SeparatorService.createSeparator(separator);
        this.loading = false;

        runInAction(() => {
            if (!response.error) {
                message.success('Separador cadastrado com sucesso!');
            } else {
                message.error(response.error);
            }
        });

        return response;
    }

    async updateSeparator(separator: SeparatorModel, uuid: string) {
        this.loading = true;
        const response = await SeparatorService.updateSeparator(uuid, separator);
        this.loading = false;

        runInAction(() => {
            if (!response.error) {
                message.success('Separador atualizado com sucesso!');
            } else {
                message.error(response.error);
            }
        });

        return response;
    }

    async deleteSeparator(uuid: string) {
        this.loading = true;
        const response = await SeparatorService.deleteSeparator(uuid);
        this.loading = false;

        runInAction(() => {
            if (!response.error) {
                message.success('Separador deletado com sucesso!');
                this.getList(this.defaultparams);
            } else {
                message.error(response.error);
            }
        });

        return response;
    }

    async deleteAllSeparator(uuid: string) {
        this.loading = true;
        const response = await SeparatorService.deleteSeparator(uuid);
        this.loading = false;

        runInAction(() => {
            if (!response.error) {
                this.separator = undefined;
            }
        });

        return response;
    }
}

const separatorStore = new SeparatorStore();
export default separatorStore;