import { makeAutoObservable, runInAction } from 'mobx';
import { DEFAULT_PAGE_SIZE } from '../config/config';
import OrganizationService from '../services/OrganizationService';
import { transformToValueLabel } from '../utils/selectFunction';
import { generateTree } from '../utils/functions';
import { OrganizationModel } from '../types/OrganizationModel';
import { UserModel } from '../types/UserModel';
import { message } from 'antd';

class OrganizationsStore {
    loading: boolean = false;
    sort?: string = '-date_created';
    organization?: OrganizationModel = undefined;
    organizationList: any[] = [];
    organizationSelect: any[] = [];
    organizationTree: any[] = [];
    organizationCustomers: UserModel[] = [];
    organizationOwnTree: any[] = [];
    totalPages: number = 0;
    page: number = 0;
    totalElements: number = 0;
    pageSize: number = DEFAULT_PAGE_SIZE;
    defaultparams = {
        pageSize: DEFAULT_PAGE_SIZE,
        page: 1,
    }


    constructor() {
        makeAutoObservable(this);
    }

    reset() {
        this.organization = undefined;
        this.organizationCustomers = [];
        this.organizationList = [];
        this.organizationSelect = [];
    }

    async getList(params: any) {
        this.loading = true;
        const response = await OrganizationService.getList(params);

        const urlParams = new URLSearchParams(params);
        const pageSize = Number(urlParams.get('pageSize') || DEFAULT_PAGE_SIZE);

        runInAction(() => {
            if (!response.error) {
                this.organizationList = response.data.content;
                this.totalPages = response.data.totalPages;
                this.page = response.data.page;
                this.totalElements = response.data.totalElements;
                this.pageSize = pageSize;
            }
            this.loading = false;
        });

        return response;
    }


    async getListOwn() {
        this.loading = true;
        const response = await OrganizationService.getListOwn();

        runInAction(() => {
            if (!response.error) {
                this.organizationOwnTree = generateTree([response.data]);
                this.organizationSelect = transformToValueLabel(response.data.children, 'uuid', 'name');
            }
            this.loading = false;
        });

        return response;
    }

    async getTree() {
        this.loading = true;
        const response = await OrganizationService.getTree();

        runInAction(() => {
            if (!response.error) {
                this.organizationTree = generateTree(response.data.content);
            }
            this.loading = false;
        });
        return response;
    }

    async getOrganizationsAsyncSelect(name: string) {
        const filter = `fullName__icontains=${name}`;
        const sort = 'full_name'
        const response = await OrganizationService.getList({ filter, sort });

        runInAction(() => {
            if (!response.error) {
                this.organizationSelect = transformToValueLabel(response.data.content, 'uuid', 'fullName');
            } else {
                message.error(response.error);
            }
            this.loading = false;
        });
        return response;
    }

    async getOrganizationByUuid(uuid: string) {
        this.loading = true;
        const response = await OrganizationService.getOrganizationByUuid(uuid);

        runInAction(() => {
            if (!response.error) {
                this.organization = response.data;
            } else {
                message.error(response.error);
            }
            this.loading = false;
        });
        return response;
    }

    async createOrganization(organization: OrganizationModel) {
        this.loading = true;
        const response = await OrganizationService.createOrganization(organization);

        runInAction(() => {
            if (!response.error) {
                message.success('Organização cadastrado com sucesso!');
            } else {
                message.error(response.error);
            }

            this.loading = false;
        });
        return response;
    }

    async editOrganization(organization: OrganizationModel, uuid: string) {
        this.loading = true;
        const response = await OrganizationService.editOrganization(organization, uuid);

        runInAction(() => {
            if (!response.error) {
                message.success('Organização editada com sucesso!');
            } else {
                message.error(response.error);
            }
            this.loading = false;
        });
        return response;
    }

    async getOrganizationCustomers(uuid: string) {
        this.loading = true;
        const response = await OrganizationService.getOrganizationCustomers(uuid);

        runInAction(() => {
            if (!response.error) {
                this.organizationCustomers = response.data;
            }
            this.loading = false;
        });
        return response;
    }

    async export(params: any) {
        this.loading = true;
        const response = await OrganizationService.export(params);
        this.loading = false;
        runInAction(() => {
            if (response.error) {
                message.error(response.error);
                return undefined;
            }
        });
        return response.data;
    }

    async import(data: any) {
        this.loading = true;
        const response = await OrganizationService.import(data);
        this.loading = false;
        if (!response.error) {
            message.success("Dados importados com sucesso!");
            this.getList(this.defaultparams);
        } else {
            message.error(response.error);
        }
        return response;
    }

    async deleteOrganization(uuid: string) {
        this.loading = true;
        const response = await OrganizationService.deleteOrganization(uuid);
        this.loading = false;
        if (!response.error) {
            message.success('Organização deletada com sucesso!');
            this.getList(this.defaultparams);
        }
        else {
            message.error(response.error);
        }
        return response;
    }
}

const organizationsStore = new OrganizationsStore();
export default organizationsStore;