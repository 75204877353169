import { DatePicker, Typography } from 'antd';
import { Field } from 'formik';
import React from 'react';
import './input.scss';

interface DatePickerComponetProps {
  label?: string;
  format?: string;
  disabled?: boolean;
  name: string; // Tornando o name obrigatório
  size?: 'large' | 'middle' | 'small';
  showTime?: boolean;
}

const { RangePicker } = DatePicker;

const DatePickerRangeComponet: React.FC<DatePickerComponetProps> = ({ label, name, size, disabled, format, showTime }) => (
  <div className="flex flex-col w-full input-container">
    {label && <Typography.Title level={5}>{label}</Typography.Title>}
    <Field name={name}>
      {({ field, form, meta }: any) => {
        const handleChange = (dates: any, dateStrings: [string, string]) => {
          form.setFieldValue(field.name, dates); // Ou dateStrings, dependendo do que você precisa
        };

        return (
          <>
            <RangePicker
              onChange={handleChange}
              value={field.value || []}
              size={size ? size : 'large'}
              disabled={disabled}
              format={format ? format : "DD-MM-YYYY"}
              status={meta.touched && meta.error ? 'error' : ''}
              showTime={showTime}
            />
            {meta.touched && meta.error && (
              <Typography.Text type="danger">{meta.error}</Typography.Text>
            )}
          </>
        );
      }}
    </Field>
  </div>
);

export default DatePickerRangeComponet;
