import URLS from '../config/urls';
import BaseAPI from './BaseApi';


class PermissionService {
  async getList(params: any): Promise<any> {
    try {
        const response = await BaseAPI.get(`${URLS.PERMISSION}`, { params });
        return response; // Retorna a resposta da API
    } catch (e) {
        return { error: "Erro ao buscar dados" }; // Retorna um objeto de erro
    }
}

async getListUserPermissions(uuid: string): Promise<any> {
    try {
      const response = await BaseAPI.get(`${URLS.PERMISSION}/user/${uuid}`);
      return response;
    } catch (e) {
      return { error: "Erro ao buscar dados" };
    }
}

async setUserPermissions(uuid: string, data: any): Promise<any> {
  try {
    const response = await BaseAPI.post(
      `${URLS.PERMISSION}/user/${uuid}`,
      data
    );
    return response;
  } catch (e) {
    return { error: "Falha ao atualizar informações" };
  }
}
}

export default new PermissionService();