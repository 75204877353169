import { DeleteOutlined, EditOutlined, MoreOutlined } from '@ant-design/icons';
import type { TableColumnsType, TableProps } from 'antd';
import { message, Space, Table } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_PAGE_SIZE, TEXT_BUTTON_HEADER_CREATE } from '../../../config/config';
import useQueryParams, { buildParamsFromQuery } from '../../../hooks/useQueryParams';
import separatorStore from '../../../stores/separatorStore';
import { exportToExcel } from '../../../utils/excelFunction';
import { getSortField, getSortOrder, getUniqueFilters, getUniquePropertyFilters } from '../../../utils/tableFunctions';
import BreadCrumbComponent from '../../atoms/BreadCrumbComponent';
import ActionButton from '../../atoms/ButtonComponent';
import ContentBox from '../../molecules/ContentBox';
import TableCardComponent from '../../molecules/TableCardComponent';
import DrawerBottom from '../../organisms/DrawerButton';
import ModalComponent from '../../organisms/ModalComponent';
import DrawerFilterSeparator from './DrawerFilterSeparator';
import FormSeparator from './FormSeparator';
import { PermissionSeparator } from '../../../permissions/PermissionSeparator';
import { useAuth } from '../../protected/ProviderAuth';
import Permission from '../../molecules/Permission';



interface DataType {
    organization: any;
    email: any;
    fullName: any;
    uuid: string;
    key: React.Key;
}

interface QueryParams {
    size?: number;
    page?: number;
    sort?: string;
    fullName__icontains?: string;
    email__icontains?: string;
    organization__fullName__icontains?: string;
}

// BreadCrumb
const breadCrumb = [
    {
        title: <a href="/dashboard">Dashboard</a>,
    },
    {
        title: <p>Lista de Separadores</p>,
    },
]

const ListSeparator: React.FC = observer(() => {
    const { separatorList, loading, page, totalElements, defaultparams, pageSize, sort } = separatorStore;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [itemSelected, setItemSelected] = useState<any>();
    const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
    const [selectionCheckbox, setSelectionCheckbox] = useState<any>();
    const [queryParams, setQueryParams] = useQueryParams();
    const [openFilter, setOpenFilter] = useState<any>();
    const listRouter = () => { navigate(`/separador/cadastro`); }
    const [bottomOptions, openBottomOptions] = useState<any>(false);
    const navigate = useNavigate();
    let formikSubmit: () => void;
    const { isColumnVisible } = useAuth();

    useEffect(() => {
        const mappings = {
            page: 'page',
            pageSize: 'pageSize',
            fullName__icontains: 'fullName__icontains',
            email__icontains: 'email__icontains',
            organization__fullName__icontains: 'organization__fullName__icontains',
            sort: 'sort',
        };

        const params = buildParamsFromQuery(queryParams, mappings);

        const fetchData = async () => {
            const urlParams = new URLSearchParams({
                page: String(params.page),
                pageSize: String(params.pageSize ? params.pageSize : DEFAULT_PAGE_SIZE),
                sort: String(params.sort),
            });

            // Adiciona cada filtro separadamente no URLSearchParams
            params.filter.forEach((filter: string) => urlParams.append('filter', filter));

            await separatorStore.getList(urlParams);
        };

        fetchData();
    }, []);


    // Colunas da Tabela
    const columns: TableColumnsType<DataType> = [
        {
            title: 'Nome',
            dataIndex: 'fullName',
            key: 'fullName',
            filters: getUniqueFilters(separatorList, 'fullName'),
            onFilter: (value, record) => record.fullName.indexOf(value as string) === 0,
            defaultSortOrder: getSortOrder('fullName', queryParams.sort),
            sorter: true,
        },
        {
            title: 'E-mail',
            dataIndex: 'email',
            key: 'email',
            filters: getUniqueFilters(separatorList, 'email'),
            onFilter: (value, record) => record.email.indexOf(value as string) === 0,
            defaultSortOrder: getSortOrder('email', queryParams.sort),
            sorter: true,
        },
        {
            title: 'Organização',
            dataIndex: 'organization',
            key: 'organization.fullName',
            filters: getUniquePropertyFilters<any, 'organization', 'fullName'>(separatorList, 'organization', 'fullName'),
            onFilter: (value, record) => record.organization?.fullName.indexOf(value as string) === 0,
            defaultSortOrder: getSortOrder('organization', queryParams.sort),
            sorter: true,
            render: (item) => {
                return (
                    <>{item && <p>{item?.fullName}</p>}</>
                );
            },
        },
        {
            title: 'Editar',
            dataIndex: 'edit',
            key: 'edit',
            align: 'center',
            hidden: !isColumnVisible(PermissionSeparator.PUT),
            width: 80,
            render: (text, record) => (
                <Space className="cursor-pointer">
                    <a href={`/separador/editar/${record.uuid}`}>
                        <div><EditOutlined /></div>
                    </a>
                </Space>
            ),
        },
        {
            title: 'Deletar',
            dataIndex: 'delete',
            key: 'delete',
            align: 'center',
            hidden: !isColumnVisible(PermissionSeparator.DELETE),
            width: 80,
            render: (text, record) => (
                <Space className="cursor-pointer" onClick={() => showModal(record)}>
                    <div ><DeleteOutlined /></div>
                </Space>
            ),
        },
    ];

    const onChange: TableProps<DataType>['onChange'] = async (pagination, filters, sorter, extra) => {
        const params = buildParams(pagination, sorter, queryParams);
        await separatorStore.getList(params);
    };

    const buildParams = (
        pagination: any,
        sorter: any,
        queryParams: QueryParams
    ) => {
        const getSort = getSortField(sorter);
        const currentPage = pagination.current;
        const pageSize = pagination.pageSize || queryParams.size || DEFAULT_PAGE_SIZE;
        const paramsUrl = {
            pageSize: pageSize,
            page: currentPage,
            sort: getSort || sort || queryParams.sort || '-date_created',
            fullName__icontains: queryParams.fullName__icontains,
            email__icontains: queryParams.email__icontains,
            organization__fullName__icontains: queryParams.organization__fullName__icontains,
        };

        // Usando URLSearchParams para montar múltiplos parâmetros `filter`
        const params = new URLSearchParams({
            page: currentPage,
            pageSize: String(DEFAULT_PAGE_SIZE),
            sort: getSort || sort || queryParams.sort || '-date_created',
        });


        if (queryParams.fullName__icontains) {
            params.append('filter', `fullName__icontains=${queryParams.fullName__icontains}`);
        }
        if (queryParams.email__icontains) {
            params.append('filter', `email__icontains=${queryParams.email__icontains}`);
        }
        if (queryParams.organization__fullName__icontains) {
            params.append('filter', `organization__fullName__icontains=${queryParams.organization__fullName__icontains}`);
        }


        setQueryParams(paramsUrl);
        return params;
    };

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
            setSelectionCheckbox(selectedRows);
        },
    };

    const showModal = (record: any) => {
        setItemSelected(record);
        setIsModalOpen(true);
    };

    const handleExport = async () => {
        const params = new URLSearchParams();

        if (queryParams.fullName__icontains) {
            params.append('filter', `fullName__icontains=${queryParams.fullName__icontains}`);
        }

        if (queryParams.email__icontains) {
            params.append('filter', `email__icontains=${queryParams.email__icontains}`);
        }

        if (queryParams.organization__fullName__icontains) {
            params.append('filter', `organization__fullName__icontains=${queryParams.organization__fullName__icontains}`);
        }
        const dataExport = await separatorStore?.export(params);

        if (dataExport) {
            exportToExcel(dataExport, "Tabela de Separadores.xlsx");
        }
    };

    const deleteAllModal = () => {
        if (selectionCheckbox?.length > 0) {
            setIsModalDeleteOpen(true);
        } else {
            message.warning('Por favor, selecione ao menos um item para realizar a exclusão.');
        }
    }


    const initialValues = {
        fullName__icontains: queryParams.fullName__icontains || '',
        email__icontains: queryParams.email__icontains || '',
        organization__fullName__icontains: queryParams.organization__fullName__icontains || '',
    };

    const handleSubmit = async (values: any) => {
        const newParams: any = {
            fullName__icontains: values.fullName__icontains || undefined,
            email__icontains: values.email__icontains || undefined,
            organization__fullName__icontains: values.organization__fullName__icontains || undefined
        };

        // Usando URLSearchParams para montar múltiplos parâmetros `filter`
        const params = new URLSearchParams({
            page: '1',
            pageSize: String(DEFAULT_PAGE_SIZE),
        });

        if (queryParams.sort) {
            params.append('sort', String(queryParams.sort));
        }

        if (values.fullName__icontains) {
            params.append('filter', `fullName__icontains=${values.fullName__icontains}`);
        }

        if (values.email__icontains) {
            params.append('filter', `email__icontains=${values.email__icontains}`);
        }

        if (values.organization__fullName__icontains) {
            params.append('filter', `organization__fullName__icontains=${values.organization__fullName__icontains}`);
        }

        // Definindo os query params atualizados
        setQueryParams(newParams);

        // Chamando a API com os parâmetros múltiplos
        await separatorStore.getList(params);
    };

    const handleDeleteItem = () => {
        separatorStore.deleteSeparator(itemSelected.uuid);
        setIsModalOpen(false);
    };

    const deleteSelectedItems = async () => {
        try {
            if (selectionCheckbox !== undefined) {
                const results = await Promise.all(
                    selectionCheckbox.map((item: any) => separatorStore.deleteAllSeparator(item.uuid))
                );
                const hasError = results.some(response => response?.error);
                if (hasError) {
                    setSelectionCheckbox([]);
                    message.error('Erro ao deletar um ou mais Separadores.');
                } else {
                    setSelectionCheckbox([]);
                    message.success('Todos os Separadores foram deletados com sucesso!');
                    await separatorStore.getList(defaultparams);
                }
                setIsModalDeleteOpen(false);
            } else {
                message.error('Selecione pelo menos um item da listagem');
            }
        } catch (error) {
            message.error('Erro ao tentar deletar os Separadores.');
        }
    };

    const editRouter = (item: any) => {
        navigate(`/separador/editar/${item.uuid}`);
    }

    const deleteItem = (item: any) => {
        const response = separatorStore.deleteSeparator(item.uuid);
        return response
    }

    return (
        <>
            <BreadCrumbComponent breadCrumbList={breadCrumb} permission={PermissionSeparator.POST} textButton={TEXT_BUTTON_HEADER_CREATE} onClick={() => listRouter()} />

            <ContentBox className="mb-4 p-6 hidden lg:block" title="Listagem de Separadores" description='Tela de listagem de Separadores'>
                <FormSeparator
                    setSubmitForm={(submitForm) => {
                        formikSubmit = submitForm;
                    }}
                    queryParams={queryParams} setQueryParams={setQueryParams}
                />
            </ContentBox >

            <ContentBox className="mb-4 p-6 hidden lg:block" description='Lista de Separadores' actions={
                <>
                    <Permission permissionKeys={[PermissionSeparator.DELETE]} removeType>
                        <ActionButton icon actionType="delete" onClick={() => deleteAllModal()}>Deletar</ActionButton>
                    </Permission>

                    <Permission permissionKeys={[PermissionSeparator.EXPORT]} removeType>
                        <ActionButton icon actionType="export" onClick={() => handleExport()}>Exportar para Excel</ActionButton>
                    </Permission>
                </>
            }>
                <Table
                    className="table-custom"
                    columns={columns}
                    dataSource={separatorList.map((item: any, index: number) => ({
                        ...item,
                        key: item.uuid || index,
                    }))}
                    onChange={onChange}
                    loading={loading}
                    rowSelection={rowSelection}
                    size="middle"
                    bordered={false}
                    pagination={{
                        current: page,
                        pageSize: pageSize,
                        total: totalElements,
                        showSizeChanger: true,
                        pageSizeOptions: ['20', '40', '60'],
                    }}
                />
            </ContentBox>

            {/* Responsive Component */}
            <TableCardComponent
                itemData={separatorList}
                titlePage="Separadores"
                subTitlePage="Tela de listagem de Separadores"
                viewItems={[{ name: 'Organização', key: 'organization.name' }]}
                titleKey="firstName"
                subTitleKey="email"
                initialValues={initialValues}
                totalElements={totalElements}
                pageSize={pageSize}
                current={page}
                onChange={onChange}
                editRouter={isColumnVisible(PermissionSeparator.PUT) ? editRouter : undefined}
                deleteItem={isColumnVisible(PermissionSeparator.DELETE) ? deleteItem : undefined}
                handleSubmit={handleSubmit}
                inputName="fullName__icontains"
                placeholder="Pesquisar Separador"
                loading={loading}
                actions={
                    <>
                        <div style={{ background: '#FFFAF2' }} onClick={() => openBottomOptions(true)} className="bg-slate-50 flex min-w-10 min-h-10 justify-center rounded-full">
                            <MoreOutlined />
                        </div>
                    </>
                }
            />

            <ModalComponent
                title="Aviso"
                content={<p>Você deseja excluir o Separador <b>{itemSelected?.firstName}</b>?</p>}
                isModalOpen={isModalOpen}
                handleOk={handleDeleteItem}
                handleCancel={() => setIsModalOpen(false)}
            />

            <ModalComponent
                title="Aviso"
                content={<p>Você está prestes a excluir vários itens da listagem, deseja prosseguir?</p>}
                isModalOpen={isModalDeleteOpen}
                handleOk={deleteSelectedItems}
                handleCancel={() => setIsModalDeleteOpen(false)}
            />

            <DrawerFilterSeparator queryParams={queryParams} setQueryParams={setQueryParams} isModalOpen={openFilter} setIsModalOpen={setOpenFilter} />

            <DrawerBottom openMenu={bottomOptions} setOpenMenu={openBottomOptions}>
                <p className="h-8" onClick={() => {
                    setOpenFilter(true);
                    openBottomOptions(false);
                }}>Filtro</p>

                <Permission permissionKeys={[PermissionSeparator.POST]} removeType>
                    <p className="h-8" onClick={() => listRouter()}>Cadastrar Separador</p>
                </Permission>

                <Permission permissionKeys={[PermissionSeparator.EXPORT]} removeType>
                    <p className="h-8" onClick={() => {
                        handleExport();
                        openBottomOptions(false);
                    }}>Exportar para Excel</p>
                </Permission>
            </DrawerBottom>
        </>
    );
});

export default ListSeparator;