import URLS from '../config/urls';
import BaseAPI from './BaseApi';


class LockersService {
    async getList(params: any): Promise<any> {
        try {
            const response = await BaseAPI.get(`${URLS.LOCKER}`, { params });
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao buscar dados" }; // Retorna um objeto de erro
        }
    }

    async export(params: any): Promise<any> {
        try {
            const response = await BaseAPI.get(URLS.LOCKER + "/csv", { params });
            return response;
        } catch (e) {
            return { error: "Falha ao gerar csv" };
        }
    }


    async getLockerByUuid(uuid: any): Promise<any> {
        try {
            const response = await BaseAPI.get(`${URLS.LOCKER}/${uuid}`);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao buscar dados" }; // Retorna um objeto de erro
        }
    }

    async getAllocationLocker(uuid: any): Promise<any> {
        try {
            const response = await BaseAPI.get(`${URLS.ALLOCATION}/locker/${uuid}/in-locker?page=1&pageSize=999`);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao buscar dados" }; // Retorna um objeto de erro
        }
    }
}

export default new LockersService();