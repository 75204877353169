import * as Yup from 'yup';

// Definindo o esquema de validação com Yup
export const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("Nome obrigatório!"),
    lastName: Yup.string().required("Sobrenome obrigatório"),
    email: Yup.string().email("E-mail inválido").required("Email obrigatório!"),
    confirmEmail: Yup.string()
    .email("E-mail inválido")
    .required("Confirmar E-mail obrigatório!")
    .oneOf([Yup.ref("email"),], "Os E-mails não são iguais"),
});

export const editValidationSchema = Yup.object().shape({
    firstName: Yup.string().required("Nome obrigatório!"),
    lastName: Yup.string().required("Sobrenome obrigatório"),
    email: Yup.string().email("E-mail inválido").required("Email obrigatório!"),
});