// Faz um filtro pelos dados da tabela com mais de uma key
export const getUniquePropertyFilters = <T, K extends keyof T, P extends keyof T[K]>(data: T[], key: K, propertyName: P) => {
    const uniqueValues = Array.from(new Set(data.map(item => item[key] ? item[key][propertyName] : ''))); // Acesso dinâmico à propriedade
    return uniqueValues.map(value => ({
        text: value,
        value: value,
    }));
};

type Filters = {
    [key: string]: string | undefined;
};

// Faz um filtro pelos dados da tabela
export const getUniqueFilters = <T>(data: T[], key: any) => {
    //@ts-ignore
    const uniqueValues = Array.from(new Set(data?.map(item => item[key] as unknown as string))); // Extrai valores únicos e faz o cast
    return uniqueValues.map(value => ({
        text: value, // Não é necessário converter novamente para string aqui, já estamos tratando como string
        value: value,
    }));
};

export const getSortOrder = (sortField: string, querySort: string | null | (string | null)[]): 'ascend' | 'descend' | undefined => {
    if (!querySort) return undefined;

    // Se for um array, pega o primeiro elemento
    const sortString = Array.isArray(querySort) ? querySort[0] : querySort;

    //@ts-ignore
    const [field, order] = sortString.split(',');

    if (field === sortField) {
        return order === 'desc' ? 'descend' : 'ascend';
    }

    return undefined;
};

export const getSortField = (config: any): string => {
    const { order, columnKey } = config;

    if (order === 'ascend') {
        return columnKey; // Retorna apenas o nome do campo
    } else if (order === 'descend') {
        return `-${columnKey}`; // Adiciona ",desc" ao nome do campo
    }

    return columnKey; // Retorna o nome do campo por padrão
};
