import { message, Table, TableColumnsType, TableProps, Tag } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import allocationStore from '../../../../stores/allocationStore';
import ContentBox from '../../../molecules/ContentBox';
import lockersStore from '../../../../stores/lockersStore';
import { useParams } from 'react-router-dom';
import { getStatusAllocation, getStatusOrderColor } from '../../../../utils/statusColorMapper';


interface DataType {
    customer: any;
    organization: any;
    email: any;
    fullName: any;
    uuid: string;
    key: React.Key;
}

const LockerListMobile: React.FC = observer(() => {
    const { lockerMobile, loading } = lockersStore;
    const { uuid } = useParams<{ uuid: string }>();
    const [selectedPorts, setSelectedPorts] = useState<any[]>([]);

    const columns: TableColumnsType<DataType> = [
        {
            title: 'Porta',
            dataIndex: 'name',
            key: 'name',
            render: (item) => {
                return (
                    <>{item && <p>Porta {item}</p>}</>
                );
            },
        },
        {
            title: 'Status',
            dataIndex: 'isAvailable',
            key: 'isAvailable',
            render: (item) => {
                let text = item ? 'Livre' : 'Ocupado';

                return (
                    <Tag color={getStatusAllocation(text)}>{text}</Tag>
                );
            },
        },
    ];

    const rowSelection = {
        onSelect: (record: DataType, selected: boolean, selectedRows: DataType[]) => {
            handleSelectPort(record);
        },
    };

    const handleSelectPort = (item: any) => {
        if (!uuid) {
            if (item.name.toLowerCase() !== 'monitor') {
                if (item.isAvailable === true) {
                    setSelectedPorts((prevSelected: any) => {
                        const isAlreadySelected = prevSelected.some((port: any) => port.container.uuid === item.uuid);

                        if (isAlreadySelected) {
                            // Remove o item se já estiver selecionado
                            return prevSelected.filter((port: any) => port.container.uuid !== item.uuid);
                        }

                        // Adiciona o item com a nova estrutura ao array
                        return [
                            ...prevSelected,
                            {
                                container: item,
                                inLocker: false,  // ou o valor desejado
                                isActive: true     // ou o valor desejado
                            }
                        ];
                    });
                } else {
                    message.error('Esta porta já está alocada. Por favor, selecione outra.');
                }
            } else {
                message.error('Não é possivel selecionar o monitor para alocação');
            }
        } else {
            message.error('Alocação já iniciada. Para selecionar uma nova porta, finalize ou crie uma nova alocação.');
        }
    };


    return (
        <>
            {!uuid &&
                <ContentBox className="mb-4 p-6 block lg:hidden" title="Selecione a Porta de Alocação" description='Selecione as Portas para Alocação'>
                    <Table
                        className="table-custom"
                        columns={columns}
                        dataSource={lockerMobile && lockerMobile.map((item: any, index: number) => ({
                            ...item,
                            key: item.uuid || index,
                        }))}
                        rowSelection={{
                            ...rowSelection,
                            getCheckboxProps: (record: any) => ({
                                disabled: !record.isAvailable || record.name.toLowerCase() === 'monitor' || uuid ? true : false,
                            }),
                        }}
                        loading={loading}
                        size="middle"
                        bordered={false}
                        pagination={false}
                    />
                </ContentBox>
            }
        </>
    );
});

export default LockerListMobile;