import { Drawer } from 'antd';
import React, { Dispatch, SetStateAction } from 'react';

interface DrawerBottomProps {
    openMenu: boolean;
    setOpenMenu: Dispatch<SetStateAction<boolean>>;
    children?: React.ReactNode
}


const DrawerBottom: React.FC<DrawerBottomProps> = ({ setOpenMenu, openMenu, children }) => {

    return (
        <Drawer title={null} headerStyle={{ display: 'none' }} bodyStyle={{ padding: '16px' }} height="auto" placement={'bottom'} onClose={() => setOpenMenu(false)} open={openMenu}>

            <div className="pb-6">
                <div className="flex justify-center" onClick={() => setOpenMenu(false)}>
                    <div className="bg-gray-400 w-7 h-1.5 rounded-full"></div>
                </div>

                <p className="py-6 text-base font-semibold">Ações da Tabela</p>

                <div className="flex gap-4 flex-col">
                    {children}
                </div>
            </div>
        </Drawer>
    );
};

export default DrawerBottom;