import { FileImageOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react-lite';
import React, { Dispatch, useState } from 'react';
import { useParams } from 'react-router-dom';
import ModalImageLocker from '../../ModalImageLocker';

interface CardLockerModuleProps {
    lockerModule?: any;
    setItemAlocation: Dispatch<any>;
}

const CardLockerModule: React.FC<CardLockerModuleProps> = observer(({ lockerModule }) => {
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [itemAlocation, setItemAlocation] = useState<any>();
    const { uuid } = useParams<{ uuid: string }>();

    return (
        <>
            <div>
                <div className="p-3 bg-slate-50 rounded-md">
                    <div className="flex justify-between pb-2">
                        <p className="text-base font-bold">Porta {lockerModule?.container?.name}</p>

                        {uuid &&
                            <div className="cursor-pointer" onClick={() => {
                                setOpenModal(true);
                                setItemAlocation(lockerModule)
                            }
                            }>
                                <FileImageOutlined />
                            </div>
                        }
                    </div>

                    <div>
                        <div className="flex justify-between">
                            <p>Tipo Porta</p>
                            <p>{lockerModule?.container?.containerType?.name}</p>
                        </div>
                        <div className="flex justify-between">
                            <p>Tamanho</p>
                            <p>{lockerModule?.container?.containerMeasure?.name}</p>
                        </div>
                    </div>
                </div>
            </div>

            {openModal && <ModalImageLocker itemAlocation={itemAlocation} title="Imagem Alocação" handleOk={() => setOpenModal(false)} isModalOpen={openModal} handleCancel={() => setOpenModal(false)} />}
        </>
    );
});

export default CardLockerModule;