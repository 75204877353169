export const StatusActive = [
    { value: '', label: 'Todos' },
    { value: true, label: 'Ativo' },
    { value: false, label: 'Inativo' }
];

export const shortState = [
  {
    value: "AC",
    label: "AC",
  },
  {
    value: "AL",
    label: "AL",
  },
  {
    value: "AP",
    label: "AP",
  },
  {
    value: "AM",
    label: "AM",
  },
  {
    value: "BA",
    label: "BA",
  },
  {
    value: "CE",
    label: "CE",
  },
  {
    value: "DF",
    label: "DF",
  },
  {
    value: "ES",
    label: "ES",
  },
  {
    value: "GO",
    label: "GO",
  },
  {
    value: "MA",
    label: "MA",
  },
  {
    value: "MT",
    label: "MT",
  },
  {
    value: "MS",
    label: "MS",
  },
  {
    value: "MG",
    label: "MG",
  },
  {
    value: "PA",
    label: "PA",
  },
  {
    value: "PB",
    label: "PB",
  },
  {
    value: "PR",
    label: "PR",
  },
  {
    value: "PE",
    label: "PE",
  },
  {
    value: "PI",
    label: "PI",
  },
  {
    value: "RJ",
    label: "RJ",
  },
  {
    value: "RN",
    label: "RN",
  },
  {
    value: "RS",
    label: "RS",
  },
  {
    value: "RO",
    label: "RO",
  },
  {
    value: "RR",
    label: "RR",
  },
  {
    value: "SC",
    label: "SC",
  },
  {
    value: "SP",
    label: "SP",
  },
  {
    value: "SE",
    label: "SE",
  },
  {
    value: "TO",
    label: "TO",
  },
];
