import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import ModalImageLocker from '../../ModalImageLocker';
import CardLockerModule from '../CardLockerModule';

interface DataType {
    organization: any;
    email: any;
    fullName: any;
    uuid: string;
    key: React.Key;
}

interface LockerMapProps {
    informationLocker?: any;
}

const LockerMap: React.FC<LockerMapProps> = observer(({ informationLocker }) => {
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [itemAlocation, setItemAlocation] = useState<any>();

    return (
        <div>
            {informationLocker &&
                <div className="border p-4 rounded-lg h-full w-full max-w-96">
                    <div className="flex flex-col gap-3">
                        {informationLocker?.map((item: any, index: number) => (
                            <div key={index}>
                                <CardLockerModule lockerModule={item} setItemAlocation={setItemAlocation} />
                            </div>
                        ))}
                    </div>
                </div>
            }

            {openModal && <ModalImageLocker itemAlocation={itemAlocation} title="Imagem Alocação" handleOk={() => setOpenModal(false)} isModalOpen={openModal} handleCancel={() => setOpenModal(false)} />}
        </div>
    );
});

export default LockerMap;