export interface SortConfig {
    order: 'ascend' | 'descend';
    field: string;
}

export const formatNumbers = (value: any, prefix = '', suffix = '') => {
    if (!value) {
        return prefix.concat('0,00').concat(suffix);
    }

    if (!Number.isNaN(value)) {
        if (value.parseFloat) {
            value = value.parseFloat(value).toFixed(2);
        } else if (value.toFixed) {
            value = value.toFixed(2);
        }
    }

    let v = value.toString().replace(/\D/g, '');
    v = `${(v / 100).toFixed(2)}`;
    v = v.replace('.', ',');
    v = v.replace(/(\d)(\d{3})(\d{3}),/g, '$1.$2.$3,');
    v = v.replace(/(\d)(\d{3}),/g, '$1.$2,');
    return prefix.concat(v).concat(suffix);
};


export const formatCurrency = (value = 0, currency = 'BRL') =>
    value.toLocaleString('pt-BR', {
        style: 'currency',
        currency,
    });




export const roundPercentage = (value: number, decimals: number = 2) => {
    const factor = Math.pow(10, decimals);
    return Math.round(value * factor) / factor;
}

// Cria um preview de imagens 
export const createPreviewImage = (values: any[]) =>
    values.map((file: any) =>
        file.preview
            ? file
            : Object.assign(file, {
                preview: URL.createObjectURL(file),
                path: file.name,
            })
    );


export const removeSpecialCharacters = (value: string) => {
    const valueToString = value?.toString();
    return valueToString?.replace(/[^a-zA-Z0-9 ]/g, '');
};

export const generateTree = (tree: any) => {
    return tree.map((t: any) => {
        return {
            ...t,
            key: t.uuid,
            title: t.name,
            children: t.children
                ? generateTree(t.children)
                : undefined,
            uuid: t.uuid
        };
    });
};


export const transformModulesToTableData = (modules: any[]): any[] => {
    return modules.map((module, index) => {
        const totalPorts = module.containers.length;
        const occupied = module.containers.filter((container: any) => !container.isAvailable).length;
        const free = totalPorts - occupied;
        const status = free > 0 ? "Livre" : "Ocupado";

        return {
            module: `Módulo ${index + 1}`, // Exemplo de nome para o módulo
            totalPorts,
            occupied,
            free,
            status,
        };
    });
};


export const getNestedValue = (obj: any, path: string): any => {
    return path.split('.').reduce((acc, key) => acc?.[key], obj);
};


export function formatContainerList(list: any[]): string {
    return list
        .map(item => {
            const measureName = item.container?.containerMeasure?.name ?? '';
            const serialName = item.container?.serialName ?? '';
            return `${measureName}-${serialName}`;
        })
        .join(', ');
}