import { makeAutoObservable, runInAction } from 'mobx';
import { DEFAULT_PAGE_SIZE } from '../config/config';
import AdministratorService from '../services/AdministratorService';
import { AdministratorModel } from '../types/AdministratorModel';
import { message } from 'antd';

class AdministratorStore {
    loading: boolean = false;
    sort?: string = '-date_created';
    administrator?: AdministratorModel = undefined;
    administratorList: any[] = [];
    totalPages: number = 0;
    page: number = 0;
    totalElements: number = 0;
    pageSize: number = DEFAULT_PAGE_SIZE;
    defaultparams = {
        pageSize: DEFAULT_PAGE_SIZE,
        page: 1,
    }


    constructor() {
        makeAutoObservable(this);
    }

    reset() {
        this.administrator = undefined;
    }

    async getList(params: any) {
        this.loading = true;
        const response = await AdministratorService.getList(params);

        const urlParams = new URLSearchParams(params);
        const pageSize = Number(urlParams.get('pageSize') || DEFAULT_PAGE_SIZE);

        runInAction(() => {
            if (!response.error) {
                this.administratorList = response.data.content;
                this.totalPages = response.data.totalPages;
                this.page = response.data.page;
                this.totalElements = response.data.totalElements;
                this.pageSize = pageSize;
            }
            this.loading = false;
        });

        return response;
    }

    async getAdministratorByUuid(uuid: string) {
        this.loading = true;
        const response = await AdministratorService.getAdministratorByUuid(uuid);
        this.loading = false;

        if (!response.error) {
            this.administrator = response.data;
        } else {
            message.error(response.error);
        }
        return response;
    }

    async export(params: any) {
        this.loading = true;
        const response = await AdministratorService.export(params);
        this.loading = false;
        runInAction(() => {
            if (response.error) {
                message.error(response.error);
                return undefined;
            }
        });
        return response.data;
    }

    async createAdministrator(administrator: AdministratorModel) {
        this.loading = true;

        const response = await AdministratorService.createAdministrator(
            administrator
        );
        this.loading = false;

        runInAction(() => {
            if (!response.error) {
                message.success('Administrador cadastrado com sucesso!');
            } else {
                message.error(response.error);
            }
        });
        return response;
    }

    async updateAdministrator(administrator: AdministratorModel, uuid: string) {
        this.loading = true;

        const response = await AdministratorService.updateAdministrator(
            uuid,
            administrator
        );
        this.loading = false;

        runInAction(() => {
            if (!response.error) {
                message.success('Administrador atualizado com sucesso!');
            } else {
                message.error(response.error);
            }
        });

        return response;
    }

    async deleteAdministrator(uuid: string) {
        this.loading = true;
        const response = await AdministratorService.deleteAdministrator(uuid);
        this.loading = false;

        runInAction(() => {
            if (!response.error) {
                message.success('Administrador deletado com sucesso!');
                this.getList(this.defaultparams);
            } else {
                message.error(response.error);
            }
        });

        return response;
    }

    async deleteAllAdministrator(uuid: string) {
        this.loading = true;
        const response = await AdministratorService.deleteAdministrator(uuid);
        this.loading = false;

        runInAction(() => {
            if (!response.error) {
                this.administrator = undefined;
            }
        });

        return response;
    }
}

const administratorStore = new AdministratorStore();
export default administratorStore;