import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import BlockInformation from './BlockInformation';
import lockersStore from '../../../stores/lockersStore';
import ContentBox from '../../molecules/ContentBox';
import { Empty } from 'antd';
import LoadingComponent from '../../molecules/LoadingComponent';

interface DataType {
    organization: any;
    email: any;
    fullName: any;
    uuid: string;
    key: React.Key;
}


interface LockerMapProps {
    isMobile?: boolean;
    handleSelectPort: (item: any) => void;
    textInformation?: string;
    selectedPorts: any;
}

const LockerMap: React.FC<LockerMapProps> = observer(({ isMobile, handleSelectPort, textInformation, selectedPorts }) => {
    const { locker, loading } = lockersStore;
    const [selectedModule, setSelectedModule] = useState(locker && locker?.modules?.length > 0 && locker?.modules[0]);

    useEffect(() => {
        if (locker && locker.modules.length > 0) {
            // Define o primeiro módulo como selecionado assim que o locker é carregado
            setSelectedModule(locker.modules[0]);
        }
    }, [locker]);

    const distributeItems = (containers: any[], moduleHeight: number) => {
        const columns: any[][] = [[]];
        let currentColumnHeight = 0;

        containers && containers.forEach((container) => {
            const itemHeight = container.containerMeasure.height;

            if (currentColumnHeight + itemHeight <= moduleHeight) {
                // Adiciona o item na coluna atual
                columns[columns.length - 1].push(container);
                currentColumnHeight += itemHeight;
            } else {
                // Cria uma nova coluna
                columns.push([container]);
                currentColumnHeight = itemHeight;
            }
        });

        return columns;
    };

    const columns = distributeItems(
        selectedModule?.containers,
        selectedModule?.height
    );

    const selectAllocationInformation = async (item: any) => {
        handleSelectPort(item);
    }

    const isEmpty = Array.isArray(columns) && columns.every(innerArray => Array.isArray(innerArray) && innerArray.length === 0);

    return (
        <div>
            {!loading &&
                <>
                    <div className="flex">
                        {locker && locker?.modules?.length > 0 && locker?.modules.map((module: any, index: number) => (
                            <div
                                key={module?.uuid}
                                className={`cursor-pointer px-4 py-2 mb-1 rounded-t-md ${module?.uuid === selectedModule?.uuid ? "bg-secondary-color text-primary-color" : "bg-slate-100"
                                    }`}
                                onClick={() => setSelectedModule(module)}
                            >
                                Módulo {index}
                            </div>
                        ))}
                    </div>

                    {/* Exibição dinâmica das colunas */}
                    <div className="flex gap-6">
                        {!isEmpty && columns?.length > 0 &&
                            <div className="flex gap-2 h-full">
                                {columns.map((column, index) => (
                                    <div key={index} className="flex flex-col gap-2">
                                        {column.map((item) => {
                                            const isSelected = selectedPorts.some((selected: any) => selected.container.name === item.name);

                                            return (
                                                <div
                                                    key={item.uuid}
                                                    onClick={() => selectAllocationInformation(item)}
                                                    className={`flex flex-col justify-between cursor-pointer p-2 min-w-44 min-h-16 rounded h-full
                                                    ${isSelected
                                                            ? "border-2 border-blue-500 bg-blue-100"
                                                            : !item.isAvailable
                                                                ? "bg-locker-background border-2 border-locker-highlight"
                                                                : "border-2" // Cor padrão
                                                        }`}
                                                >
                                                    <div className="flex justify-between">
                                                        <p className="">{item.name}</p>

                                                        {item.name.toLowerCase() !== 'monitor' && (
                                                            <div className={`rounded min-h-6 min-w-6 bg-gray-200 flex justify-center ${!item.isAvailable ? "bg-gray-50" : ""
                                                                }`}><p className={`${!item.isAvailable ? "" : ""
                                                                    }`}>{item.containerMeasure.name}</p></div>
                                                        )}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                ))}
                            </div>
                        }

                        {selectedPorts?.length === 0 ?
                            <div className="border p-2 rounded-lg h-full w-full">
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Clique em uma das portas para selecionar a alocação" />
                            </div> : <div className="w-full">
                                <BlockInformation informationLocker={selectedPorts} />
                            </div>
                        }
                    </div>
                </>
            }

            {loading &&
                <LoadingComponent />
            }
        </div>
    );
});

export default LockerMap;