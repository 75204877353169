import { EditOutlined, MoreOutlined } from '@ant-design/icons';
import type { TableColumnsType, TableProps } from 'antd';
import { Space, Table } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_PAGE_SIZE } from '../../../config/config';
import useQueryParams, { buildParamsFromQuery } from '../../../hooks/useQueryParams';
import lockersStore from '../../../stores/lockersStore';
import { exportToExcel } from '../../../utils/excelFunction';
import { getSortField, getSortOrder, getUniqueFilters, getUniquePropertyFilters } from '../../../utils/tableFunctions';
import BreadCrumbComponent from '../../atoms/BreadCrumbComponent';
import ActionButton from '../../atoms/ButtonComponent';
import ContentBox from '../../molecules/ContentBox';
import TableCardComponent from '../../molecules/TableCardComponent';
import DrawerBottom from '../../organisms/DrawerButton';
import DrawerFilterLockers from './DrawerFilterLockers';
import FormLockers from './FormLockers';
import { PermissionLocker } from '../../../permissions/PermissionLocker';
import Permission from '../../molecules/Permission';
import { useAuth } from '../../protected/ProviderAuth';

interface DataType {
    containersCount: any;
    organization: any;
    name: string;
    uuid: string;
    key: React.Key;
}

interface QueryParams {
    size?: number;
    page?: number;
    sort?: string;
    name__icontains?: string;
    organization__fullName__icontains?: string;
}

// BreadCrumb
const breadCrumb = [
    {
        title: <a href="/dashboard">Dashboard</a>,
    },
    {
        title: <p>Lista de Lockers</p>,
    },
]

const ListLockers: React.FC = observer(() => {
    const { lockersList, loading, page, totalElements, defaultparams, pageSize, sort } = lockersStore;
    const [queryParams, setQueryParams] = useQueryParams();
    const [openFilter, setOpenFilter] = useState<boolean>(false);
    const navigate = useNavigate();
    const [bottomOptions, openBottomOptions] = useState<any>(false);
    let formikSubmit: () => void;
    const { isColumnVisible } = useAuth();

    useEffect(() => {
        const mappings = {
            page: 'page',
            pageSize: 'pageSize',
            sort: 'sort',
            name__icontains: 'name__icontains',
            organization__fullName__icontains: 'organization__fullName__icontains',
        };

        const params = buildParamsFromQuery(queryParams, mappings);

        const fetchData = async () => {
            const urlParams = new URLSearchParams({
                page: String(params.page),
                pageSize: String(params.pageSize ? params.pageSize : DEFAULT_PAGE_SIZE),
                sort: String(params.sort),
            });

            // Adiciona cada filtro separadamente no URLSearchParams
            params.filter.forEach((filter: string) => urlParams.append('filter', filter));

            await lockersStore.getList(urlParams);
        };

        fetchData();
    }, []);


    // Colunas da Tabela
    const columns: TableColumnsType<DataType> = [
        {
            title: 'Nome',
            dataIndex: 'name',
            key: 'name',
            filters: getUniqueFilters(lockersList, 'name'),
            onFilter: (value, record) => record.name.indexOf(value as string) === 0,
            defaultSortOrder: getSortOrder('name', queryParams.sort),
            sorter: true,
        },
        {
            title: 'Organização',
            dataIndex: 'organization',
            key: 'organization.fullName',
            filters: getUniquePropertyFilters<any, 'organization', 'fullName'>(lockersList, 'organization', 'fullName'),
            onFilter: (value, record) => record.organization?.fullName.indexOf(value as string) === 0,
            defaultSortOrder: getSortOrder('organization__fullName', queryParams.sort),
            sorter: true,
            render: (item) => {
                return (
                    <>{item && <p>{item?.fullName}</p>}</>
                );
            },
        },
        {
            title: 'Qtde. de Portas',
            dataIndex: 'containersCount',
            key: 'containersCount',
        },
        {
            title: 'Editar',
            dataIndex: 'edit',
            key: 'edit',
            align: 'center',
            width: 80,
            render: (text, record) => (
                <Space className="cursor-pointer">
                    <a href={`/locker/editar/${record.uuid}`}>
                        <div><EditOutlined /></div>
                    </a>
                </Space>
            ),
        },
    ];

    const onChange: TableProps<DataType>['onChange'] = async (pagination, filters, sorter, extra) => {
        const params = buildParams(pagination, sorter, queryParams);
        await lockersStore.getList(params);
    };

    const buildParams = (
        pagination: any,
        sorter: any,
        queryParams: QueryParams
    ) => {
        const getSort = getSortField(sorter);
        const currentPage = pagination.current;
        const pageSize = pagination.pageSize || queryParams.size || DEFAULT_PAGE_SIZE;
        const paramsUrl = {
            pageSize: pageSize,
            page: currentPage,
            sort: getSort || sort || queryParams.sort || '-date_created',
            name__icontains: queryParams.name__icontains,
            organization__fullName: queryParams.organization__fullName__icontains,
        };

        const params = new URLSearchParams({
            page: currentPage,
            pageSize: String(DEFAULT_PAGE_SIZE),
            sort: getSort || sort || queryParams.sort || '-date_created',
        });

        if (queryParams.name__icontains) {
            params.append('filter', `name__icontains=${queryParams.name__icontains}`);
        }
        if (queryParams.organization__fullName__icontains) {
            params.append('filter', `organization__fullName__icontains=${queryParams.organization__fullName__icontains}`);
        }

        setQueryParams(paramsUrl);
        return params;
    };

    const handleExport = async () => {
        const params = new URLSearchParams();

        if (queryParams.name__icontains) {
            params.append('filter', `name__icontains=${queryParams.name__icontains}`);
        }

        if (queryParams.organization__fullName__icontains) {
            params.append('filter', `organization__fullName__icontains=${queryParams.organization__fullName__icontains}`);
        }

        const dataExport = await lockersStore?.export(params);

        if (dataExport) {
            exportToExcel(dataExport, "Tabela de Lockers.csv");
        }
    };

    const initialValues = {
        name__icontains: queryParams.name__icontains || '',
        organization__fullName__icontains: queryParams.organization__fullName__icontains || '',
    };

    const handleSubmit = async (values: any) => {
        const newParams: any = {
            name__icontains: values.name__icontains || undefined,
            organization__fullName__icontains: values.organization__fullName__icontains || undefined,
            sort: queryParams.sort,
        };

        // Usando URLSearchParams para montar múltiplos parâmetros `filter`
        const params = new URLSearchParams({
            page: '1',
            pageSize: String(DEFAULT_PAGE_SIZE),
        });

        if (queryParams.sort) {
            params.append('sort', String(queryParams.sort));
        }

        if (newParams.name__icontains) {
            params.append('filter', `name__icontains=${newParams.name__icontains}`);
        }

        if (newParams.organization__fullName__icontains) {
            params.append('filter', `organization__fullName__icontains=${newParams.organization__fullName__icontains}`);
        }

        // Definindo os query params atualizados
        setQueryParams(newParams);

        // Chamando a API com os parâmetros múltiplos
        await lockersStore.getList(params);
    };

    const editRouter = (item: any) => {
        navigate(`/locker/editar/${item.uuid}`);
    }

    return (
        <>
            <BreadCrumbComponent breadCrumbList={breadCrumb} />

            <ContentBox className="mb-4 p-6 hidden lg:block" title="Listagem de Lockers" description='Tela de listagem de Lockers'>
                <FormLockers
                    setSubmitForm={(submitForm) => {
                        formikSubmit = submitForm;
                    }}
                    queryParams={queryParams} setQueryParams={setQueryParams}
                />
            </ContentBox >

            <ContentBox className="mb-4 p-6 hidden lg:block" description='Lista de Lockers' actions={
                <>
                    <ActionButton icon actionType="export" onClick={() => handleExport()}>Exportar para Excel</ActionButton>
                </>
            }>
                <Table
                    className="table-custom"
                    columns={columns}
                    dataSource={lockersList.map((item: any, index: number) => ({
                        ...item,
                        key: item.uuid || index,
                    }))}
                    onChange={onChange}
                    loading={loading}
                    size="middle"
                    bordered={false}
                    pagination={{
                        current: page,
                        pageSize: pageSize,
                        total: totalElements,
                        showSizeChanger: true,
                        pageSizeOptions: ['20', '40', '60'],
                    }}
                />
            </ContentBox>

            {/* Responsive Component */}
            <TableCardComponent
                itemData={lockersList}
                titlePage="Lockers"
                viewItems={[{ name: 'Organização', key: 'organization.name' }]}
                titleKey="name"
                subTitlePage="Tela de listagem de Lockers"
                initialValues={initialValues}
                totalElements={totalElements}
                pageSize={pageSize}
                current={page}
                onChange={onChange}
                editRouter={editRouter}
                handleSubmit={handleSubmit}
                inputName="name__icontains"
                placeholder="Pesquisar Lockers"
                loading={loading}
                actions={
                    <>
                        <div style={{ background: '#FFFAF2' }} onClick={() => openBottomOptions(true)} className="bg-slate-50 flex min-w-10 min-h-10 justify-center rounded-full">
                            <MoreOutlined />
                        </div>
                    </>
                }
            />

            <DrawerFilterLockers queryParams={queryParams} setQueryParams={setQueryParams} isModalOpen={openFilter} setIsModalOpen={setOpenFilter} />

            <DrawerBottom openMenu={bottomOptions} setOpenMenu={openBottomOptions}>
                <p className="h-8" onClick={() => {
                    setOpenFilter(true);
                    openBottomOptions(false);
                }}>Filtro</p>

                <Permission permissionKeys={[PermissionLocker.EXPORT]} removeType>
                    <p className="h-8" onClick={() => {
                        handleExport();
                        openBottomOptions(false);
                    }}>Exportar para Excel</p>
                </Permission>
            </DrawerBottom>
        </>
    );
});

export default ListLockers;