export const getStatusColor = (status: string): string => {
    switch (status) {
        case 'Ativo':
            return 'green'; // azul pastel
        case 'Inativo':
            return 'red'; // laranja pastel
        case 'Sim':
            return 'green'; // azul pastel
        case 'Não':
            return 'red'; // laranja pastel
        default:
            return 'gray'; // Cor padrão para status desconhecido
    }
};


export const getStatusOrderColor = (status: string): string => {
    switch (status) {
        case 'REVERSED':
            return 'volcano'; // laranja pastel
        case 'REVERSING':
            return 'orange'; // laranja pastel
        case 'RETRIEVED':
            return 'lime'; // laranja pastel
        case 'SCHEDULED':
            return 'purple'; // laranja pastel
        case 'CANCELED':
            return 'red';
        case 'RETRIVING':
            return 'gold'; // azul pastel
        case 'READY':
            return 'green'; // azul pastel
        case 'Pronto para retirada':
            return 'green'; // azul pastel
        case 'Alocando':
            return 'cyan'; // laranja pastel
        case 'ALLOCATING':
            return 'cyan'; // laranja pastel
        case 'Novo':
            return 'blue'; // azul pastel
        case 'NEW':
            return 'blue'; // azul pastel
        case 'Sim':
            return 'green'; // azul pastel
        case 'Não':
            return 'red'; // azul pastel
        default:
            return 'purple'; // Cor padrão para status desconhecido
    }
};


export const getStatusAllocation = (status: string): string => {
    switch (status) {
        case 'Livre':
            return 'green'; // azul pastel
        case 'Ocupado':
            return 'gray'; // laranja pastel
        default:
            return 'gray'; // Cor padrão para status desconhecido
    }
};