
interface Item {
    [key: string]: any; // Definindo que a chave do objeto pode ser qualquer string
}

export const transformToValueLabel = (
    data: Item[],
    valueKey: string,
    labelKey: string
) => {
    return data.map(item => ({
        value: item[valueKey],
        label: item[labelKey],
        option: item,
    }));
};