import URLS from '../config/urls';
import { OrganizationModel } from '../types/OrganizationModel';
import BaseAPI from './BaseApi';


class OrganizationService {
    async getList(params: any): Promise<any> {
        try {
            const response = await BaseAPI.get(`${URLS.ORGANIZATION}`, { params });
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao buscar dados" }; // Retorna um objeto de erro
        }
    }


    async getListOwn(): Promise<any> {
        try {
            const response = await BaseAPI.get(`${URLS.ORGANIZATION}/own`);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao buscar dados" }; // Retorna um objeto de erro
        }
    }

    async getTree(): Promise<any> {
        try {
          const response = await BaseAPI.get(`${URLS.ORGANIZATION}/tree`);
          return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao buscar dados" }; // Retorna um objeto de erro
        }
      }

    async getOrganizationByUuid(uuid: string): Promise<any> {
        try {
            const response = await BaseAPI.get(`${URLS.ORGANIZATION}/${uuid}`);
            return response;
        } catch (e) {
            return { error: "Erro ao buscar dados" };
        }
    }

    async editOrganization(data: OrganizationModel, uuid: string): Promise<any> {
        try {
          const response = await BaseAPI.put(`${URLS.ORGANIZATION}/${uuid}`, data);
          return response;
        } catch (e: any) {
          return { error: e?.response?.data?.detail || e?.response?.data?.message || "Erro ao cadastrar Organização" };
        }
      }
    
    async createOrganization(data: OrganizationModel): Promise<any> {
        try {
          const response = await BaseAPI.post(URLS.ORGANIZATION, data);
          return response;
        } catch (e: any) {
            return { error: e?.response?.data?.detail || e?.response?.data?.message || "Erro ao editar Organização" };   
        }
      }

    async deleteOrganization(uuid: string): Promise<any> {
        try {
          const response = await BaseAPI.delete(`${URLS.ORGANIZATION}/${uuid}`);
          return response;
        } catch (e: any) {
          return { error: e?.response?.data?.detail || e?.response?.data?.message || "Erro ao excluir Organização" }; 
        }
      }

      async getOrganizationCustomers(uuid: string): Promise<any> {
        try {
          const response = await BaseAPI.get(
            `${URLS.ORGANIZATION}/${uuid}/customers`
          );
          return response;
        } catch (e) {
            return { error: "Erro ao buscar dados" };
        }
      }

      async export(params: any): Promise<any> {
        try {
          const response = await BaseAPI.get(URLS.ORGANIZATION + "/csv", { params });
          return response;
        } catch (e) {
          return { error: "Falha ao gerar csv" };
        }
      }
    
      async import(data: any): Promise<any> {
        try {
          const response = await BaseAPI.post(URLS.ORGANIZATION + "/csv", data, {
            headers: {
              'Content-Type': 'multipart/form-data', // Define o tipo de conteúdo correto
          },
          });
          if (response.status === 201) return true;
        } catch (e) {
          return { error: "Falha ao gerar csv" };
        }
      }
}

export default new OrganizationService();