// src/components/organisms/LoginForm/index.tsx
import React, { Dispatch, SetStateAction, useState } from 'react';
import LoginContainer from './LoginContainer';
import ForgoutPassword from './ForgoutPassword';
import CodeValidation from './CodeValidation';
import ChangePassword from './ChangePassword';
import { observer } from 'mobx-react-lite';
import authStore from '../../../stores/authStore';


interface LoginFormProps {
    setStep: Dispatch<SetStateAction<number>>;
}


const LoginForm: React.FC<LoginFormProps> = observer(({ setStep }) => {
    const { loading } = authStore;
    const [recoverStep, setRecoverStep] = useState(0);
    const [email, setEmail] = useState("");
    const [token, setToken] = useState("");

    return (
        <div className="p-4">
            {recoverStep === 0 &&
                <LoginContainer setStep={setStep} setRecoverStep={setRecoverStep} loading={loading} />
            }

            {recoverStep === 1 &&
                <ForgoutPassword setStep={setRecoverStep} setEmail={setEmail} loading={loading} />
            }

            {recoverStep === 2 &&
                <CodeValidation setStep={setRecoverStep} setToken={setToken} loading={loading} />
            }

            {recoverStep === 3 &&
                <ChangePassword setStep={setRecoverStep} email={email} token={token} loading={loading} />
            }

            <p className="absolute copyright">Stoom 2024 © Todos os Direitos reservados</p>
        </div>
    );
});

export default LoginForm;