import Cookies from 'js-cookie';
import React from 'react';
import { COOKIE_NAMES } from '../../../config/cookieConstants';


const UserHeaderComponent: React.FC = () => {
    const email = Cookies.get(COOKIE_NAMES.EMAIL);
    const name = Cookies.get(COOKIE_NAMES.NAME);

    return (
        <div className="flex gap-2 items-center" onClick={(e) => e.preventDefault()}>
            <img className='text-2xl max-w-11' src="/assets/avatar.png" alt="user" />

            <div className="flex flex-col">
                <p className="text-sm font-bold">{name}</p>
                <p className="dark:text-white">{email}</p>
            </div>
        </div>
    )
};

export default UserHeaderComponent;