import { makeAutoObservable, runInAction } from 'mobx';
import { DEFAULT_PAGE_SIZE } from '../config/config';
import { message } from 'antd';
import PermissionService from '../services/PermissionService';

class PermissionStore {
    loading: boolean = false;
    sort?: string = '-date_created';
    permission = undefined;
    permissions: any = [];
    permissionsAsyncSelect: any = [];
    ownPermissions = [];
    totalPages: number = 0;
    page: number = 0;
    totalElements: number = 0;
    pageSize: number = DEFAULT_PAGE_SIZE;
    defaultparams = {
        pageSize: DEFAULT_PAGE_SIZE,
        page: 1,
    }

    constructor() {
        makeAutoObservable(this);
    }

    reset() {
        this.permission = undefined;
        this.permissions = [];
        this.permissionsAsyncSelect = [];
    }

    pushPermission(permissionToPush: any) {
        this.permissions.push(permissionToPush);
        this.permissionsAsyncSelect = this.permissionsAsyncSelect.filter(
            (i: any) =>
                !this.permissions.some(
                    (selectedPermission: any) =>
                        selectedPermission.codename === i.value
                )
        );
    }

    removePermission(permissionToRemove: any) {
        this.permissions = this.permissions.filter(
            (i: any) => i.codename !== permissionToRemove.codename
        );
        this.permissionsAsyncSelect.push(permissionToRemove);
    }

    async getAsyncSelectList(name: string) {
        this.loading = true;
        const params = { filter: `name__icontains=${name}` };
        const response = await PermissionService.getList(params);
        this.loading = false;

        runInAction(() => {
            if (!response.error) {
                const formattedPermissions = response.data.map((permission: any) => {
                    return { label: permission.name, value: permission.codename };
                });

                const filteredPermissions = formattedPermissions.filter(
                    (i: any) =>
                        !this.permissions.some(
                            (selectedPermission: any) =>
                                selectedPermission.codename === i.value.codename));

                this.permissionsAsyncSelect = filteredPermissions;
            } else {
                message.error(response.error);
            }
        });

        return response;
    }

    async getListUserPermissions(uuid: string) {
        this.loading = true;
        const response = await PermissionService.getListUserPermissions(uuid);
        this.loading = false;

        runInAction(() => {
            if (!response.error) {
                this.permissions = response.data;
            } else {
                message.error(response.error);
            }
        });
        return response;
    }

    async setUserPermissions(uuid: string, data: any) {
        this.loading = true;
        const response = await PermissionService.setUserPermissions(uuid, data);
        this.loading = false;

        runInAction(() => {
            if (!response.error) {
                this.permissions = response.data;
            } else {
                message.error(response.error);
            }
        });
        return response;
    }
}

const permissionStore = new PermissionStore();
export default permissionStore;