import URLS from '../config/urls';
import BaseAPI from './BaseApi';

class AuthService {
  //Lass
  async authentication2fa(data: any): Promise<any> {
    try {
      const response = await BaseAPI.post(`${URLS.MFA}`, data);
      return response; // Retorna a resposta da API
    } catch (e) {
      return { error: "Erro ao logar" }; // Retorna um objeto de erro
    }
  }

  async login(data: any): Promise<any> {
    try { // Presumo que 'data' seja esse objeto
      const response = await BaseAPI.post(URLS.LOGIN, data);
      return response; // Retorna a resposta da API
    } catch (e) {
      return { error: "Não autenticado" }; // Retorna um objeto de erro
    }
  }


  // Não Lass

  /**Faz requisição de redefinição de senha do email informado */
  async redefinePass(email: string): Promise<any> {
    try {
      const response = await BaseAPI.post(URLS.FORGET_PASSWORD, { email });
      return response;
    } catch (e) {
      return { error: "A recuperação de senha falhou" };
    }
  }

  async validateToken(token: string): Promise<any> {
    try {
      const response = await BaseAPI.post(`${URLS.FORGET_PASSWORD}validate_token/`, { token });
      return response;
    } catch (e) {
      return { error: "A recuperação de senha falhou" };
    }
  }

  async changePassword(token: string, password: string): Promise<any> {
    try {
      const response = await BaseAPI.post(`${URLS.FORGET_PASSWORD}confirm/`, {
        token,
        password,
      });
      return response;
    } catch (e) {
      return { error: "A recuperação de senha falhou" };
    }
  }


  async logout(): Promise<void> {
    // Lógica para logout
  }

  async verifyTwoFactorAuth() {

  }

  // async getCurrentUser(): Promise<User | null> {
  //     // Lógica para obter o usuário atual
  // }
}

export default new AuthService();