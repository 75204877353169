import { Input, Typography } from 'antd';
import { Field } from 'formik';
import React from 'react';
import InputMask from 'react-input-mask';
import './input.scss';

//@ts-ignore
interface InputMaskComponentProps extends React.InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    placeholder?: string;
    maxLength?: number;
    disabled?: boolean;
    value?: string;
    showMaxLength?: boolean;
    name: string; // Tornando o name obrigatório
    size?: string;
    hidden?: boolean;
    mask?: string; // Propriedade de máscara opcional
}

const InputMaskComponent: React.FC<InputMaskComponentProps> = ({ label, hidden, showMaxLength, value, maxLength, name, size, disabled, placeholder, mask, ...props }) => (
    <div className="flex flex-col w-full input-container">
        {label && <Typography.Title level={5}>{label}</Typography.Title>}
        <Field name={name}>
            {({ field, form, meta }: any) => {
                const inputProps = {
                    ...field,
                    maxLength,
                    disabled,
                    value: value ? value : field.value,
                    placeholder,
                    status: meta.touched && meta.error ? 'error' : ''
                };

                const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
                    const { value } = event.target;
                    // Limpeza do valor para que fique somente os caracteres válidos
                    const cleanedValue = value.replace(/[^\d]/g, ''); // Ajuste esta regex conforme necessário
                    form.setFieldValue(name, cleanedValue);
                };

                return (
                    <>
                        {mask ? (
                            //@ts-ignore
                            <InputMask
                                mask={mask}
                                value={field.value}
                                onChange={handleChange}
                                disabled={disabled}
                                placeholder={placeholder}
                                //@ts-ignore
                                size={size ? size : "large"}
                            >
                                {/* @ts-ignore */}
                                {(inputProps: any) => <Input {...inputProps} />}
                            </InputMask>
                        ) : (
                            <Input {...inputProps} onChange={handleChange} />
                        )}
                        {meta.touched && meta.error && (
                            <Typography.Text type="danger">{meta.error}</Typography.Text>
                        )}
                    </>
                );
            }}
        </Field>
    </div>
);

export default InputMaskComponent;
