import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import lockersStore from '../../../../stores/lockersStore';
import ContentBox from '../../../molecules/ContentBox';
import BlockInformation from './BlockInformation';


interface LockerMapProps {
    isMobile?: boolean;
}

const LockerMap: React.FC<LockerMapProps> = observer(({ isMobile }) => {
    const { locker, allocationList } = lockersStore;
    const [informationLocker, setInformationLocker] = useState<any>();
    const [selectedModule, setSelectedModule] = useState(locker?.modules[0]);

    const distributeItems = (containers: any[], moduleHeight: number) => {
        const columns: any[][] = [[]];
        let currentColumnHeight = 0;

        containers && containers.forEach((container) => {
            const itemHeight = container.containerMeasure.height;

            if (currentColumnHeight + itemHeight <= moduleHeight) {
                // Adiciona o item na coluna atual
                columns[columns.length - 1].push(container);
                currentColumnHeight += itemHeight;
            } else {
                // Cria uma nova coluna
                columns.push([container]);
                currentColumnHeight = itemHeight;
            }
        });

        return columns;
    };

    const columns = distributeItems(
        selectedModule?.containers,
        selectedModule?.height
    );

    const selectAllocationInformation = async (item: any) => {
        const matchingAllocations = allocationList.flatMap((entry: any) =>
            entry.containerAllocations
                .filter((allocation: any) =>
                    allocation.container.name === item.name
                )
                .map((allocation: any) => ({
                    ...allocation,
                    information: entry
                }))
        );
        setInformationLocker(matchingAllocations[0]);
    }

    return (
        <div>
            {!isMobile &&
                <ContentBox className="mb-4 p-6" borderCustom='rounded-b-lg' title="Gerenciamento Porta a Porta" description='Tela para Gerenciamento porta a porta dos lockers' actions={
                    <div>
                        <div className="flex gap-2 items-center">
                            <div className="w-3 h-3 bg-locker-background rounded-full"></div>
                            <p>Portas Alocadas</p>
                        </div>
                        <div className="flex gap-2 items-center">
                            <div className="w-3 h-3 bg-locker-background-free rounded-full"></div>
                            <p>Portas Livres</p>
                        </div>
                    </div>
                }>
                    <div className="flex">
                        {locker.modules.map((module: any, index: number) => (
                            <button
                                key={module.uuid}
                                className={`px-4 py-2 mb-1 rounded-t-md ${module.uuid === selectedModule.uuid ? "bg-secondary-color text-primary-color" : "bg-slate-100"
                                    }`}
                                onClick={() => setSelectedModule(module)}
                            >
                                Módulo {index}
                            </button>
                        ))}
                    </div>

                    {/* Exibição dinâmica das colunas */}
                    <div className="flex gap-6">

                        {columns.length > 0 &&
                            <div className="flex gap-2 flex-col">
                                {columns.map((column, index) => (
                                    <div key={index} className="flex flex-col gap-2">
                                        {column.map((item) => {
                                            return (
                                                <div onClick={() => selectAllocationInformation(item)} className={`flex flex-col justify-between cursor-pointer p-2 min-w-44 min-h-16 rounded h-full ${!item.isAvailable ? "bg-locker-background border-2 border-locker-highlight" : "border-locker-highlight-free bg-locker-background-free border-2"
                                                    }   ${informationLocker?.container.name === item?.name ? "border-2 border-2 border-blue-500 bg-blue-100" : ""}`}>
                                                    <div className="flex justify-between">
                                                        <p className="">{item.name}</p>

                                                        {item.name.toLowerCase() !== 'monitor' && (
                                                            <div className={`rounded min-h-6 min-w-6 bg-gray-200 flex justify-center ${!item.isAvailable ? "bg-gray-50" : "bg-locker-highlight-free"
                                                                }`}><p className={`${!item.isAvailable ? "" : ""
                                                                    }`}>{item.containerMeasure.name}</p></div>
                                                        )}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                ))}
                            </div>
                        }

                        <div className="w-full">
                            <BlockInformation informationLocker={informationLocker} />
                        </div>
                    </div>
                </ContentBox>
            }

            {isMobile &&
                <p>TESTE</p>
            }
        </div>
    );
});

export default LockerMap;