import { DeleteOutlined } from '@ant-design/icons';
import { Button, Space, Table, TableColumnsType } from 'antd';
import { observer } from 'mobx-react-lite';
import { TreeProps } from 'rc-tree';
import React, { Dispatch, SetStateAction, useState } from 'react';
import administratorStore from '../../../../stores/administratorStore';
import permissionStore from '../../../../stores/permissionStore';
import { AddressModel } from '../../../../types/AddressModel';
import { OrganizationModel } from '../../../../types/OrganizationModel';
import AutocompleteComponent from '../../../atoms/AutocompleteComponent';
import ActionButton from '../../../atoms/ButtonComponent';
import ContentBox from '../../../molecules/ContentBox';

interface DataType {
    organization: any;
    email: any;
    fullName: any;
    uuid: string;
    key: React.Key;
}


const PermissionList: React.FC = observer(() => {
    const [permissionSelected, setPermissionSelected] = useState<any>();
    const { permissions, permissionsAsyncSelect } = permissionStore;
    const { loading } = administratorStore;

    const handlePermissionSearch = async (name: string) => {
        await permissionStore.getAsyncSelectList(name);
    };

    const handlePermissionAdd = async () => {
        permissionStore.pushPermission({
            codename: permissionSelected?.option?.value,
            name: permissionSelected?.option?.label
        });
    };

    const handlePermissionRemove = async (permission: any) => {
        permissionStore.removePermission(permission);
    };

    const columns: TableColumnsType<DataType> = [
        {
            title: 'Nome',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Deletar',
            dataIndex: 'delete',
            key: 'delete',
            align: 'center',
            render: (text, record) => (
                <Space className="cursor-pointer" onClick={() => handlePermissionRemove(record)}>
                    <div ><DeleteOutlined /></div>
                </Space>
            ),
        },
    ];


    return (
        <ContentBox className="mb-4 p-4 lg:p-6" borderCustom='rounded-b-lg' title="Listagem de permissões" description='Selecione permissões de separador'>
            <div className="flex gap-4 pb-4">
                <div className="w-full">
                    <AutocompleteComponent
                        name='permission'
                        placeholder='Nome da Permissão'
                        fetchOptions={(name) => handlePermissionSearch(name)}
                        options={permissionsAsyncSelect}
                        onChange={(value) => setPermissionSelected(value)}
                    />
                </div>
                <Button type="primary" onClick={() => handlePermissionAdd()}>Adicionar</Button>
            </div>
            <Table
                className="table-custom"
                columns={columns}
                dataSource={permissions?.map((item: any, index: number) => ({
                    ...item,
                    key: item.uuid || index,
                }))}
                loading={loading}
                size="middle"
                bordered={false}
                pagination={false}
            />
        </ContentBox>
    );
});

export default PermissionList;