import React from 'react';
import { Breadcrumb, Button } from 'antd';
import Permission from '../../molecules/Permission';

interface BreadCrumbComponentProps {
    breadCrumbList: any;
    onClick?: () => void;
    textButton?: string;
    permission?: any;
}

const BreadCrumbComponent: React.FC<BreadCrumbComponentProps> = ({ breadCrumbList, onClick, textButton, permission }) => {
    return (
        <div className="flex justify-between items-center pb-4  hidden lg:flex">
            <Breadcrumb
                items={breadCrumbList}
            />

            {/* <Permission permissionKeys={[permission]}> */}
            {onClick &&
                <Button onClick={onClick} type="primary">
                    {textButton ? textButton : 'Listagem'}
                </Button>
            }
            {/* </Permission> */}
        </div>
    );
};

export default BreadCrumbComponent;